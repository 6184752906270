import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewPanelComponent } from '../new-panel/new-panel.component';
import { PanelService, PanelModel } from "../panel.Service";
import { Observable, catchError, map, startWith, switchMap, timer, of as observableOf } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { PanelSelectorComponent } from '../panel-selector/panel-selector.component';
import { plainToClass } from 'class-transformer';
import { CintFeasibility } from '../cint.Service';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormComponent } from '../templates/base.form.component';
import { ActionEvent, EnhancedListComponent } from '../templates/enhanced-list/enhanced-list.component';

@Component({
  selector: 'app-panels',
  templateUrl: './panels.component.html',
  styleUrls: ['./panels.component.css']
})
export class PanelsComponent extends BaseFormComponent implements AfterViewInit {
  filters: string[] = ['Personal', 'Cint'];
  actions: string[] = ['Edit', 'Export', 'Delete'];
  count: number = 0;
  sb?: MatSnackBarRef<any>;
  allowUpdates: boolean;
  panelsTemp?: Observable<PanelModel[]>;
  panels: PanelModel[] = [];
  expandedElement: PanelModel | null = null;
  resultsLength = 0;
  waiting?: Observable<any>;
  waitTime: Observable<any> = timer(0, 3000);

  @ViewChild('list') list: EnhancedListComponent | undefined;

  constructor(
    public translate: TranslateService,
    private dialog: MatDialog,
    private panelService: PanelService,
    public snackbar: MatSnackBar,
    private router: Router
  ) {
    super(snackbar)
    this.allowUpdates = true;
    this.panelService.panelCount().subscribe(result => { this.count = result });
  }

  ngAfterViewInit(): void {
    this.list?.paginator?.page
      .pipe(
        startWith({}),
        switchMap(() => {
          super.openSnackbar("Loading", "-");
          let page = (this.list.paginator?.pageIndex ?? 0) + 1;
          return this.panelService.getPagedPanels(page, this.list.paginator.pageSize).pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          /*if (data != null && data.length < this.surveyPageSize) {

            this.setTotal((this.paginator?.pageIndex ?? 0) * this.surveyPageSize + data.length);
          }*/

          super.dismiss();

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          return data;
        }),
      )
      .subscribe(data => (this.panels = data));
  }

  getData() {
    let page = (this.list.paginator?.pageIndex ?? 0) + 1;
    if (page > 1 && (this.list.paginator.pageIndex * this.list.paginator?.pageSize) >= this.list.total) {
      page--;
    }

    if (this.list.paginator != undefined) {
      this.list.paginator.pageIndex = page - 1;
    }

    super.openSnackbar("Loading", "-");
    this.panelService.getPagedPanels(page, this.list.paginator.pageSize).subscribe(
      result => {
        if (result.length == 0) {
          this.list.paginator?.pageIndex == 0 ? 0 : page;
        }
        else {
          this.panels = result;
        }

        super.dismiss();
      });
  }

  newPanelDialog(): void {
    const dialogRef = this.dialog.open(NewPanelComponent,
      {});

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        let panel: PanelModel = result.data;
        this.panels.splice(0, 0, panel);
        switch (panel.PanelType) {
          case 0:
          case 2:
            this.editCint(panel);
            break;
          case 1:
            this.router.navigate(['/uploadRespondents-template', panel.PanelID, "panel"]);
            break;
        }
      }
    });
  }

  doAction($event: ActionEvent) {
    switch ($event.action) {
      case "add":
        this.newPanelDialog();
        break;
      case "edit":
        this.edit($event.data[0]);
        break;
      case "filter":
        switch ($event.data[0].toString().toLowerCase()) {
          case "personal":
            break;
          case "cint":
            break;
        }

        break;
      case "delete":
        this.deletePanel($event.data[0]);
        break;
    }
  }

  renamePanel(panel: PanelModel): void {
    const dialogRef = this.dialog.open(NewPanelComponent,
      { data: { id: panel.PanelID } });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        let panel: PanelModel = result.data;
        for (let i = 0; i < this.panels.length; i++) {
          if (this.panels[i].PanelID === panel.PanelID) {
            this.panels[i] = panel;
            return;
          }
        }
      }
    });
  }

  editPanel(panel: PanelModel) {
    switch (panel.PanelType) {
      case 0:
      case 2:
        this.allowUpdates = false;
        super.dismiss();
        this.editCint(panel);
        break;
      case 1:
        this.allowUpdates = false;
        super.dismiss();
        this.router.navigate(['/edit-respondents', panel.PanelID, 'panel']);
        break;
    }
  }

  editCint(panel: PanelModel) {
    let quota = plainToClass(CintFeasibility, panel.Quota);
    const audDialog = this.dialog.open(PanelSelectorComponent, { width: '80%', data: { quota: quota, questionCount: 5 } });
    audDialog.afterClosed().subscribe(result => {
      if (result && result.quota.Feasibility) {
        quota = result.quota.Feasibility;
        panel.Quota = JSON.stringify(quota);
        panel.PanellistCount = quota.Limit;
        this.panelService.updatePanel(panel).subscribe(
          result => {
            for (let i = 0; i < this.panels.length; i++) {
              if (this.panels[i].PanelID == panel.PanelID) {
                this.panels[i] = panel;
                break;
              }
            }

          });
      }
    });
  }

  edit(id: PanelModel) {
    if (this.canEdit && id.Summary && !id.IsCint) {
      this.editPanel(id);
      return;
    }

    if (this.canEdit && (!(id.Summary == undefined) || id.IsCint)) {
      this.allowUpdates = false;
      this.editPanel(id);
      return;
    }

    this.onSelected(id);
  }

  deletePanel(id: PanelModel) {
    this.allowUpdates = false;
    super.dismiss();
    this.panelService.delete(id.PanelID).subscribe(
      result => {
        for (let i = 0; i < this.panels.length; i++) {
          if (this.panels[i].PanelID === id.PanelID) {
            this.panels.splice(i, 1);
            this.getData();
            return;
          }
        }
      });
  }

  copyPanel(panel: PanelModel) {
    this.allowUpdates = false;
    super.dismiss();
    this.panelService.copyPanel(panel.PanelID).subscribe(
      result => {
      });
  }

  get panelPageSize(): number {
    return this.canEdit ? 20 : 10;
  }


  @Input()
  get canEdit(): boolean {
    return this._canEdit;
  }
  set canEdit(value: boolean) {
    this._canEdit = value;
  }
  private _canEdit = true;

  @Output()
  selected: EventEmitter<PanelModel> = new EventEmitter<PanelModel>();

  registerOnSelected(fn: any): void {
    this.onSelected = fn;;
  }

  onSelected(id: PanelModel) {
    this.selected.emit(id);
  }
}
