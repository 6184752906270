import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, Organization } from '../authentication.Service';

@Component({
  selector: 'app-sms-home',
  templateUrl: './sms-home.component.html',
  styleUrls: ['./sms-home.component.css']
})
export class SmsHomeComponent {
  transferType: string = "";
  name: string = "";
  isOrg: boolean = false;
  organization: Organization;
  isOwner: boolean = false;
  isGlobal: boolean = false;

  constructor(private authService: AuthenticationService,
    private actRoute: ActivatedRoute,
    public router: Router
  ) {
    this.name = this.authService.getGivenName();
    let i = this.authService.currentOrganization();
    this.isOrg = i > 0;
    if (this.isOrg) {
      this.authService.getOrganization(i.toString()).subscribe(result => {
        this.organization = result
        this.isOwner = this.organization.OwnerId == this.authService.getUserId();
      });
    }
    if (this.actRoute.snapshot.params.type) {
      switch (this.actRoute.snapshot.params.type) {
        case "keys":
          this.transferType = "keys";
          break;
        case "global":
          this.isGlobal = true;
          break;
      }
    }
  }

  get isBetaTester(): boolean {
    return this.authService.isAuthorized(['beta']);
  }

  networkManager() {
    this.router.navigate(['network']);
  }

  buynumbermanager() {
    this.router.navigate(['buynumber']);
  }

  gotoOrganization() {
    this.router.navigate(['/profile', this.organization.MemberId ]);
  }

  newEntity(what: string) {
    switch (what) {
      case "organization":
        this.router.navigate(['/organization', 0, 'smshomeorg']);
        break;
      case "key":
        this.router.navigate(['/securitykey', 0, 'smshomekey']);
        break;
      case "webook":
        this.router.navigate(['/webhook', 0, 'smshomewebhook']);
        break;
      case "keyword":
        this.router.navigate(['/keyword', 0, 'smshomekeyword']);
        break;
      case "key1":
        this.router.navigate(['/organization', 0, 'smshomeorg']);
        break;

    }
  }
}
