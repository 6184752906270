import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { NetworkModel, NetworkService, NumberModel, RequestModel } from '../../network.Service';
import { MessageService, MobileCountryInfo } from '../../message.Service';
import { CountryModel } from '../../panel.Service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-manage-network',
  templateUrl: './manage-network.component.html',
  styleUrls: ['./manage-network.component.css']
})
export class ManageNetworkComponent {
  formGroup: FormGroup;
  dataReady: boolean = false;
  countrycode: number = 0;
  networkforcountry: number = 0;


  columns: string[] = ['name', 'country', 'number', 'created', 'amended', 'whiteliststatus', 'registrationstatus', 'action'];
  requests: RequestModel[] = [];
  countries: MobileCountryInfo[] = [];
  dataSource = new MatTableDataSource<RequestModel>();
  networks: NetworkModel[] = [];
  numbers: NumberModel[] = [];


  @ViewChild('table', { static: true }) table: any | undefined;

  constructor(public translate: TranslateService,
    private messageService: MessageService,
    private router: Router,
    private networkSerivce: NetworkService
  ) {
    this.messageService.getCountries().subscribe(result => {
      this.countries = result;
    });
    this.formGroup = new FormGroup({
      Country: new FormControl()
    });
    this.networkSerivce.getNetworks().subscribe(result => { this.networks = result; });
    this.networkSerivce.getRequests().subscribe(result => {
      for (let i = 0; i < result.length; i++) {
        this.requests.push(plainToClass(RequestModel, result[i]));
      }

      this.dataSource = new MatTableDataSource<RequestModel>(this.requests);
    })
  }

  newRequest() {
    this.router.navigate(["/networkrequest", 0, "request"]);
  }

  newSearch() {
    this.countrycode = parseInt(this.formGroup.value.Country?.Codes[0]);
    this.networkSerivce.getNetworkbyCountryCode(this.countrycode).subscribe(result => {
      this.networks = result;
      this.networkSerivce.getNumbers(this.networks[0].NetworkId).subscribe(result => {
        this.numbers = result;
        this.dataReady = true;
      });
    });
  }

  edit(request: RequestModel) {
    this.router.navigate(["/networkrequest", request.RequestId, "request"]);
  }

  delete(request: RequestModel) {

  }

  getNetwork(request: RequestModel): NetworkModel {
    if (request.getNetwork() == null || request.getNetwork().NetworkId == 0) {
      for (let i = 0; i < this.networks.length; i++) {
        if (request.NetworkId == this.networks[i].NetworkId) {
          request.setNetwork(this.networks[i]);
          return request.getNetwork();
        }
      }
    }

    return request.getNetwork();
  }
}
