import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Message, MessageService, MessageSummaryModel, MobileCountryInfo, ReceiptModel } from '../../message.Service';
import { catchError, map, Observable, startWith, switchMap, of as observableOf } from "rxjs";
import { AuthenticationService, Metadata } from '../../authentication.Service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MetadataTableComponent } from '../../Development/metadata-entrytable/metadata-table.component';
import { SurveyModel } from '../../survey.Service';
import { SnackbarDefaultComponent } from '../../snackbar/snackbar-default.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { UserFeatures, UserProfile, UserService } from '../../user.Service';

@Component({
  selector: 'app-message-history',
  templateUrl: './message-history.component.html',
  styleUrls: ['./message-history.component.css']
})
export class MessageHistoryComponent implements OnInit {
  sb: MatSnackBarRef<any> | undefined;
  mess?: Observable<any>;
  messages: Message[] = [];
  total: number = 0;
  subTotal: number = 0;
  formGroup: FormGroup;
  range: FormGroup;
  displayedColumns: string[] = ['TranDate', 'Survey', 'Country', 'Source', 'Destination', 'Inbound', 'Message', 'Type', 'Segments', 'Status'];
  metadata: Metadata[] = [];
  startDate: Date = null;
  endDate: Date = null;
  isSearching: boolean = false;
  isExporting: boolean = false;
  isLoadingResults: boolean = false;
  countries: MobileCountryInfo[] = [];
  index: number;
  data: any;
  page: number = 0;
  pageSize: number = 20;
  isRateLimitReached: boolean = false;
  ismapped: boolean = false;
  userFeatures: UserFeatures = new UserFeatures();
  users: UserProfile[] = [];

  @ViewChild('messageDetail') messageDetailDialog: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild('metadataTable') metadataTable: MetadataTableComponent | undefined;
  @ViewChild('statuses') statuses: MatSelect | undefined;

  constructor(private dialog: MatDialog, private messageService: MessageService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private snackbar: MatSnackBar
  ) {
    this.formGroup = new FormGroup({
      Country: new FormControl(),
      Number: new FormControl(),
      Direction: new FormControl(),
      MessageType: new FormControl(),
      Status: new FormControl(),
      Content: new FormControl()
    });

    this.startDate = new Date();
    this.startDate.setTime(this.startDate.getTime() - ((24 * 60 * 60 * 1000) * 5));
    this.endDate = new Date();
    this.formGroup.controls.Direction.patchValue("0");
    this.formGroup.controls.Status.patchValue(["-1"]);
    this.formGroup.controls.MessageType.setValue("0");
    this.messageService.getCountries().subscribe(result => {
      this.countries = result;
    });
    this.userService.getUserFeatures().subscribe(result => {
      this.userFeatures = result;
    });
  }

  ngOnInit(): void {
  }

  processSearch(): void {
    this.paginator?.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
          this.page = (this.paginator?.pageIndex ?? 0) + 1;
          this.getTotal();
          return this.messageService.get(this.users.length > 0 ? this.users[0].UserID : 0, this.page, this.pageSize, this._survey?.SurveyID ?? (this.system ? -1 : 0), this.startDate ?? new Date("2001-01-01"),
            this.endDate ?? new Date("2040-01-01"),
            this.formGroup.value.Country?.Codes, this.formGroup.value.Number, this.metadataTable?.metadata ?? [],
            Number(this.formGroup.controls.Direction.value), this.buildStatuses(),
            this.formGroup.value.Content, Number(this.formGroup.value.MessageType))
            .pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;
          this.sb?.dismiss();

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          return data;
        }),
      )
      .subscribe(data =>
        (this.messages = data)
      );
  }

  showMore(message: Message) {
  }

  isAdmin(): boolean {
    return false;
  }

  getReceipt(receipt: ReceiptModel): string {
    if (this.isAdminUser || this.isImpersonating()) {
      return receipt?.GatewayReceiptID
    }

    let value = receipt?.GatewayReceiptID.split(":") ?? [];
    if (value.length > 1) {
      return value[1];
    }

    return "";
  }


  getType(message: Message) {
    switch (message.MessageType) {
      case 0:
        return "SMS";
      case 1:
        return "WHATSAPP";
      case 2:
        return "FACEBOOK";
      case 3:
        return "WHATSAPP TEMPLATE";
    }

    return "UNKONWN";
  }

  searchForMessages() {
    if (this.formGroup.invalid) {
      return;
    }

    let s = this.buildStatuses();
    this.isSearching = true;
    this.messages = [];
    this.page = 0;
    this.getMessages();
  }

  getTotal() {
    this.messageService.GetTotal(this.users.length > 0 ? this.users[0].UserID : 0, this.page, this.pageSize, this._survey?.SurveyID ?? (this.system ? -1 : 0), this.startDate ?? new Date("2001-01-01"),
      this.endDate ?? new Date("2040-01-01"),
      this.formGroup.value.Country?.Codes, this.formGroup.value.Number, this.metadataTable?.metadata ?? [],
      Number(this.formGroup.controls.Direction.value), this.buildStatuses(),
      this.formGroup.value.Content, Number(this.formGroup.value.MessageType)).subscribe(
        result => {
          this.total = result;
        }
      );
  }

  getMessages() {
    this.getTotal();
    this.page = 1;

    if (this.paginator != undefined) {
      this.paginator.pageIndex = this.page - 1;
    }

    if (!this.ismapped) {
      this.processSearch();
      this.ismapped = true;
      return;
    }

    this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
    this.messageService.get(this.users.length > 0 ? this.users[0].UserID : 0, this.page, this.pageSize, this._survey?.SurveyID ?? (this.system ? -1 : 0), this.startDate ?? new Date("2001-01-01"),
      this.endDate ?? new Date("2040-01-01"),
      this.formGroup.value.Country?.Codes, this.formGroup.value.Number, this.metadataTable?.metadata ?? [],
      Number(this.formGroup.controls.Direction.value), this.buildStatuses(),
      this.formGroup.value.Content, Number(this.formGroup.value.MessageType)).subscribe(
        result => {
          if (result.length == 0) {
            this.paginator?.pageIndex == 0 ? 0 : this.page;
          }
          else {
            this.messages = result;
          }

          this.sb?.dismiss();
        });
  }

  get isAdminUser(): boolean {
    return this.authenticationService.isAuthorized(['Admin']);
  }

  isImpersonating() {
    return this.authenticationService.impersonate();
  }

  buildStatuses(): number[] {
    let s: number[] = [];
    this.formGroup.controls.Status.value.forEach((data: string) => {
      switch (data) {
        case '-1':
          break;
        default:
          s.push(Number(data));
          break;
          /*
        case '1':
          s.push(0); s.push(11);
          break;
        case '2':
          s.push(1);
          break;
        case '3':
          s.push(3); s.push(4); s.push(5); s.push(6); s.push(8); s.push(9);
          s.push(10); s.push(12); s.push(13); s.push(14); s.push(16); s.push(17); s.push(18);
          s.push(19); s.push(20); s.push(21); s.push(22); s.push(23); s.push(24);
          break;
        case '4':
          s.push(4); s.push(7);
          break;
        case '5':
          s.push(8); s.push(12); s.push(16); s.push(22);
          break;
        case '6':
          s.push(6);
          break;
        case '7':
          s.push(10); s.push(19); s.push(21);
          break;
        case '8':
          s.push(15);
          break;*/
      }
    });
    return s;
  }

  exportMessages() {
    let s = this.buildStatuses();
    let date = new Date();
    this.sb = this.snackbar.open("Exporting...", "");
    this.messageService.export(this.users.length > 0 ? this.users[0].UserID : 0, this._survey?.SurveyID ?? (this.system ? -1 : 0), this.startDate ?? new Date("2001-01-01"), this.endDate ?? new Date("2040-01-01"),
      this.formGroup.value.Country?.Codes, this.formGroup.value.Number, this.metadataTable?.metadata ?? [],
      Number(this.formGroup.controls.Direction.value), s, this.formGroup.value.Content, Number(this.formGroup.value.MessageType))
      .subscribe(
        response => {
          this.downLoadFile(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ".xlsx")
        },
        error => {
          this.sb?.dismiss();
        });
    return false;
  }

  downLoadFile(data: any, type: string, ext: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    //Download by dynamically creating a tag
    const a = document.createElement('a');
    const fileName = "messages";
    a.href = url;
    // a.download = fileName;
    a.download = fileName + ext;
    a.click();
    window.URL.revokeObjectURL(url);
    this.sb?.dismiss();
  }

  selectStatus(status: any) {
    this.statuses?.options.forEach((option: MatOption) => {
      if (option.value === status) {
        if (option.selected) {
          if (status === '-1') {
            this.statuses?.options.forEach((data: MatOption) => {
              if (data.value !== "-1") {
                data.deselect();
              }
            });
          }
          else {
            this.statuses?.options.first.deselect();
          }
        }
      }
    });
  }

  showMessageDetails(index: number, row: any) {
    this.index = index;
    this.checkReceipts();
    this.data = row;
    let dialogRef = this.dialog.open(this.messageDetailDialog,
      {
        width: '80%'
      });
  }

  checkReceipts() {
    let m = this.messages[this.index];
    if (m.Segments > 1 && m.Receipts.length < m.Segments) {
      this.messageService.GetReceiptsForMessage(m.TranID).subscribe(
        result => {
          m.Receipts = result;
        });
    }
  }

  previousMessage() {
    this.index--;
    this.checkReceipts();
    this.data = this.messages[this.index];
  }

  nextMessage() {
    this.index++;
    this.checkReceipts();
    this.data = this.messages[this.index];
  }

  @Input()
  get survey(): SurveyModel {
    return this._survey;
  }
  set survey(value: SurveyModel) {
    this._survey = value;
  }
  private _survey: SurveyModel;

  @Input()
  get system(): boolean {
    return this._system;
  }
  set system(value: boolean) {
    this._system = value;
    if (this._system) {
      this.displayedColumns = ['TranDate', 'Account', 'Survey', 'Country', 'Source', 'Destination', 'Inbound', 'Message', 'Type', 'Segments', 'Status'];
      this.startDate = new Date();
      this.startDate.setTime(this.startDate.getTime() - ((3 * 60 * 60 * 1000)));
    }
  }
  private _system: boolean = false;
}
