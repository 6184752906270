<div class="user-details-container">
  <form *ngIf="tempUser | async; else loading">
    <div class="user-details-header-container">
      <div *ngIf="isOrganization && currentOrganization.Name != '' || !isOrganization" class="user-details-header-divs">
        <div class="user-profile-name">
          <app-avatar [size]="45" paddingTop="10px" name="{{isOrganization ? currentOrganization.Name : user.UserName}}" [media]="media"></app-avatar>
        </div>
      </div>
      <div *ngIf="!isOrganization" class="user-details-header-divs">
        <h3><strong>{{user.FirstName}} {{user.LastName}}</strong> ({{user.UserName}})</h3>
        {{viewingStatus}}
      </div>
      <div *ngIf="isOrganization && currentOrganization.Name != ''" class="user-details-header-divs">
        <h3><strong>{{currentOrganization.Description}}</strong> ({{currentOrganization.Name}})</h3>
        {{viewingStatus}}
      </div>
      <div *ngIf="tempOrgs | async; else loadingAccounts">
        <button *ngIf="organizations && organizations.length > 1" mat-stroked-button [matMenuTriggerFor]="accountMenu">
          <mat-icon>supervisor_account</mat-icon>
          <span>{{ isOrganization ? 'Switch to Org/User' : 'Switch to Organization'}}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #accountMenu="matMenu">
          <ng-template matMenuContent>
            <button mat-menu-item *ngFor="let org of organizations; let i = index" (click)="changeAccount(i, $event)">
              <mat-icon>{{i == 0 ? 'person' : 'supervisor_account' }}</mat-icon>
              <span>{{org.Name}}</span>
            </button>
          </ng-template>
        </mat-menu>
      </div>
    </div>
    <mat-accordion *ngIf="isOrganization && currentOrganization.Name != '' || !isOrganization" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <mat-expansion-panel hideToggle [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="simple-row">
              <mat-icon class="mini-fab">{{ isOrganization ? 'supervisor_account' : 'person' }}</mat-icon><span class="user-details-expansion-panel-title">{{ isOrganization ? 'General' : 'Account' }}</span>
            </div>
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>
        <p *ngIf="!isOrganization">
          Created:{{ user.CreatedDate | date:'medium' }} and Last Updated:{{ user.LastUpdatedDate | date:'medium' }}
        </p>
        <p *ngIf="isOrganization">
          Created:{{ currentOrganization.Created | date:'medium' }} and Last Updated:{{ currentOrganization.Amended | date:'medium' }}
        </p>
        <p *ngIf="!isOrganization">
          Last Logged In:{{user.Member.LastLoggedIn | date:'medium'}}
        </p>
        <p *ngIf="isAdminUser && !isOrganization">
          User ID: {{user.UserID}}
        </p>
        <p *ngIf="isAdminUser && isOrganization">
          Organization ID: {{currentOrganization.MemberId}}
        </p>
        <div *ngIf="isAdminUser">
          <div *ngIf="tempSurveys | async; else loadingSurveys">
            <div>Number of surveys: {{surveys.length}}</div>
            <div *ngIf="draftSurveys > 0">Draft Surveys: {{draftSurveys}} last updated {{ latestDraft | date:'medium' }}</div>
            <div *ngIf="liveSurveys > 0">Live Surveys: {{liveSurveys}} last updated {{latestLive | date:'medium'}}</div>
            <div *ngIf="completedSurveys > 0">Completed Surveys: {{completedSurveys}} last updated {{latestCompleted | date:'medium'}}</div>
            <div *ngIf="panel && panels > 0">Number of panels: {{panels}} last updated {{panel.LastUpdatedDate | date:'medium'}}</div>
          </div>
          <div>
            <button mat-raised-button color="primary" (click)="export()">Export Surveys</button>&nbsp;&nbsp;
            <button mat-raised-button color="primary" (click)="import()">Import Surveys</button>
          </div>
          <div>
            &nbsp;
          </div>
        </div>
        <div class="fieldRowWithPadding">
          <div>
            <mat-form-field appearance="outline" class="longField">
              <mat-label>{{ isOrganization ? 'Name' : 'Username' }}</mat-label>
              <input matInput placeholder="{{isOrganization ? 'Name' : 'Username'}}" readonly formControlName="UserName">
            </mat-form-field>&nbsp;&nbsp;
          </div>
          <div>
            <button mat-raised-button color="primary" *ngIf="(!isOrganization && this.currentUser?.UserName != 'admin') || isOrganization" (click)="changeUsername()">{{ isOrganization ? 'Change Name' : 'Change Username' }}</button>
          </div>
        </div>
        <div *ngIf="!isOrganization" class="fieldRowWithPadding">
          <div>
            <mat-form-field appearance="outline" class="longField">
              <mat-label *ngIf="!isAdminUser && user.Status === 3">Email (Unconfirmed)</mat-label>
              <mat-label *ngIf="isAdminUser || user.Status !== 3">Email</mat-label>
              <input matInput placeholder="Email" [readonly]="!isAdminUser" formControlName="EmailAddress">
            </mat-form-field>&nbsp;&nbsp;
          </div>
          <div>
            <button *ngIf="!isAdminUser" mat-raised-button (click)="changeEmailAddress()" color="primary">Change Email Address</button>
          </div>
        </div>
        <div *ngIf="isOrganization">
          <mat-form-field appearance="outline" class="longField">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Description" formControlName="Description">
          </mat-form-field>
        </div>
        <div *ngIf="isAdminUser" class="user-details-roles-div">
          <span>Roles: </span>&nbsp;&nbsp;
          <mat-button-toggle-group #group="matButtonToggleGroup" multiple formControlName="Roles">
            <mat-button-toggle value="sa">Survey Administrator</mat-button-toggle>
            <mat-button-toggle *ngIf="!isOrganization" value="Admin">System Administrator</mat-button-toggle>
            <mat-button-toggle value="diy">DIY Testers</mat-button-toggle>
            <mat-button-toggle value="sms">SMS Access</mat-button-toggle>
            <mat-button-toggle value="developer">Developer</mat-button-toggle>
            <mat-button-toggle value="advanced">Advanced</mat-button-toggle>
            <mat-button-toggle *ngIf="!isOrganization" value="template">Template</mat-button-toggle>
            <mat-button-toggle value="beta">Beta Tester</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div *ngIf="isAdminUser" class="user-details-status-div">
          <span>Status: </span>&nbsp;&nbsp;
          <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="Status">
            <mat-button-toggle [value]="3" [checked]="user.Status == 3">Unconfirmed</mat-button-toggle>
            <mat-button-toggle [value]="1" [checked]="user.Status == 1">Active</mat-button-toggle>
            <mat-button-toggle [value]="2" [checked]="user.Status == 2">Suspended</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div *ngIf="isAdminUser && !isOrganization" class="user-details-update-account-div">
          <button mat-raised-button color="primary" (click)="updateAccountDetails()">Update Account Details</button>&nbsp;
        </div>
        <div *ngIf="isOrganization" class="user-details-update-account-div">
          <button mat-raised-button color="primary" (click)="updateGeneralDetails(false)">Update General Details</button>&nbsp;
        </div>
        <div *ngIf="isAdminUser && !isCurrentUser && isOrganization">
          <button mat-raised-button color="primary" (click)="onImpersonate($event)">Impersonate as Owner</button>&nbsp;
          <button mat-raised-button color="primary" (click)="onSignin($event)">Signin as Owner</button>
        </div>
        <div *ngIf="isAdminUser && !isCurrentUser && !isOrganization">
          <button mat-raised-button color="primary" (click)="resetPassword()">Reset Password</button>&nbsp;
          <button mat-raised-button color="primary" (click)="onImpersonate($event)">Impersonate</button>&nbsp;
          <button mat-raised-button color="primary" (click)="onSignin($event)">Signin</button>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="simple-row">
              <mat-icon class="mini-fab">folder_shared</mat-icon><span class="user-details-expansion-panel-title">Profile</span>
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="user-details-profile-pic-container">
          <p>
            Your profile can help you with your experience using DIY Surveys.
          </p>
          <h3>Profile Picture</h3>
          <div *ngIf="media == null" class="user-details-upload-pic-container">
            <div class="user-details-avatarIcon">{{initials}}</div>
            <div class="user-details-upload-pic-btn-container">
              <input type="file" #file placeholder="Choose file" (change)="uploadPicture($event)" class="noDisplay" accept="image/x-png, image/gif, image/jpeg">
              <button mat-raised-button color="primary" (click)="file.click()">Upload Picture</button>
            </div>
          </div>
          <div *ngIf="media != null" class="user-details-upload-pic-container">
            <div>
              <input type="file" #file placeholder="Choose file" (change)="uploadPicture($event)" class="noDisplay" accept="image/x-png, image/gif, image/jpeg">
              <img [src]="media | safe: 'resourceUrl'" alt="Profile Image" class="user-details-avatarImage">
            </div>
            <div class="user-details-upload-pic-btn-container">
              <button mat-raised-button color="primary" [matMenuTriggerFor]="editPicture">Edit</button>
              <mat-menu #editPicture="matMenu">
                <button mat-menu-item (click)="file.click()">Upload New picture</button>
                <button mat-menu-item (click)="deleteMediaForProfile()">Delete Picture</button>
              </mat-menu>
            </div>
          </div>
        </div>
        @if (!isOrganization) {
        <h3>Profile Details</h3>
        <div class="wrapFlex">
          <mat-form-field appearance="outline" class="user-details-fields-width">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="First Name" formControlName="FirstName">
          </mat-form-field>&nbsp;&nbsp;
          <mat-form-field appearance="outline" class="user-details-fields-width">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Last Name" formControlName="LastName">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="user-details-fields-width">
            <mat-label>Bio</mat-label>
            <textarea matInput placeholder="Bio" formControlName="Bio"></textarea>
          </mat-form-field>
        </div>
        <div>
          <app-country-combo [selected]="country" (selectedChange)="country=$event.id"></app-country-combo>
        </div>
        <div>
          <mat-form-field appearance="outline" class="user-details-fields-width">
            <mat-label>Company</mat-label>
            <input matInput placeholder="Company" formControlName="Company">
          </mat-form-field>&nbsp;&nbsp;
          <mat-form-field appearance="outline" class="user-details-fields-width">
            <mat-label>Job Role</mat-label>
            <mat-select formControlName="JobRole" placeholder="Job Role">
              <mat-option>None</mat-option>
              <ng-container *ngFor="let jobRole of jobRoles">
                <mat-option [value]="jobRole.id">{{jobRole.value}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="user-details-fields-width">
            <mat-label>Company Sector</mat-label>
            <mat-select formControlName="CompanySector" placeholder="Company Sector">
              <mat-option>None</mat-option>
              <ng-container *ngFor="let cs of companySectors">
                <mat-option [value]="cs.id">{{cs.value}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>&nbsp;&nbsp;
          <mat-form-field appearance="outline" class="user-details-fields-width">
            <mat-label>Company Size</mat-label>
            <mat-select formControlName="CompanySize" placeholder="Company Size">
              <mat-option>None</mat-option>
              <ng-container *ngFor="let companySize of companySizes">
                <mat-option [value]="companySize.id">{{companySize.value}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="example-chip-list user-details-fields-width" appearance="outline">
            <mat-label>Your Interests</mat-label>
            <mat-chip-grid #chipList aria-label="Your Interests Selection">
              <mat-chip-row *ngFor="let interest of interests"
                            (removed)="remove(interest)">
                {{interest}}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
              <input placeholder="Interests..."
                     #interestInput
                     formControlName="YourInterests"
                     [matAutocomplete]="auto"
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     (matChipInputTokenEnd)="add($event)">
            </mat-chip-grid>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let interest of filteredInterests | async" [value]="interest">
                {{interest}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox formControlName="MakeProfilePrivate"><strong>Make profile private and hide from user searches</strong><br />Enabling this will hide your account from those wishing to invite you to organization and future social features.</mat-checkbox>
        </div>
        }
        @if (isAdminUser && !isOrganization) {
        <h3>Zendesk Connection</h3>
        <p>
          In order to connect the account to a specific Zendesk account, enter the Username.
        </p>
        <mat-form-field appearance="outline" class="user-details-fields-width">
          <mat-label>Zendesk Username</mat-label>
          <input matInput placeholder="Zendesk Username" formControlName="ZendeskUsername">
        </mat-form-field>&nbsp;&nbsp;
        }
        <div class="user-details-updtProfile-btn-div">
          <button mat-raised-button color="primary" (click)="updateBasicDetails()">Update Profile</button>&nbsp;
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="isCurrentUser && !isOrganization" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="simple-row">
              <mat-icon class="mini-fab">shield</mat-icon><span class="user-details-expansion-panel-title">Security Settings</span>
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <div>
            <mat-form-field appearance="outline" class="user-details-pwdFields-width">
              <mat-label>Old password</mat-label>
              <input matInput placeholder="Old password" [type]="opHide ? 'password' : 'text'" formControlName="OldPassword">
              <mat-icon class="reveal" matSuffix (click)="opHide = !opHide">{{opHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="user-details-pwdFields-width">
              <mat-label>New password</mat-label>
              <input matInput placeholder="New password" [type]="pHide ? 'password' : 'text'" formControlName="NewPassword">
              <mat-icon class="reveal" matSuffix (click)="pHide = !pHide">{{pHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="user-details-pwdFields-width">
              <mat-label>Confirm password</mat-label>
              <input matInput [type]="cpHide ? 'password' : 'text'" placeholder="Confirm Password" formControlName="ConfirmPassword" (keyup)="checkPassword($event)">
              <mat-icon class="reveal" matSuffix (click)="cpHide = !cpHide">{{cpHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
          </div>
          <div>
            Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter.
          </div>
          <div class="fieldRowWithPadding">
            <div>
              <button mat-raised-button color="primary" (click)="changePassword()" [disabled]="passwordChangeInvalid">Update Password</button>&nbsp;&nbsp;
            </div>
            <div>
              <button mat-button [routerLink]="'/forgotpassword'">Forgot Password?</button>
            </div>
          </div>
          <div *ngIf="!show2FA" class="user-details-twoFA-container">
            <div class="leftFloat">
              <mat-icon aria-hidden="false" aria-label="Example home icon">security</mat-icon>
            </div>
            <div class="leftFloat">
              <p>Two step verification gives you additional security by requiring a Verification code whenever you sign in on a new device. Learn more</p>
            </div>
            <div class="clearBoth">
              <button mat-raised-button color="primary" (click)="show2FASteps(true)">Enable Two step verification</button>&nbsp;
            </div>
          </div>
          <app-two-factor-autentication (cancel)="show2FASteps(false)" [user]="user" *ngIf="show2FA"></app-two-factor-autentication>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="isDIYTestersUser" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="simple-row">
              <mat-icon class="mini-fab">tune</mat-icon><span class="user-details-expansion-panel-title">Experience Preferences</span>
              <mat-icon matTooltip="DIY Tester Feature" class="mini-fav">cruelty_free</mat-icon>
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <p>
            Adjust your preferences to suit your needs
          </p>
          <h3 *ngIf="isAdminUser">General</h3>
          <div *ngIf="isAdminUser">
            <mat-button-toggle-group formControlName="Theme">
              <mat-button-toggle value="light">Light</mat-button-toggle>
              <mat-button-toggle value="dark">Dark</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <br/>
          <h3>Professional Editor</h3>
          <p>Select whether you want the full professional editor for survey management.</p>
          <mat-checkbox class="example-margin" formControlName="ProfessionalEditor"><strong>Professional Editor</strong></mat-checkbox>
          <br />
          <br/>
          <h3>Dashboard</h3>
          <p>Select which items you want to see on your dashboard, then drag and drop them into the order you want them to appear.</p>
          <div cdkDropList (cdkDropListDropped)="drop($event)">
            <div *ngFor="let preference of preferences; let i = index" class="fieldRowWithPadding" cdkDrag>
              <div class="question-sidebar-custom-placeholder" *cdkDragPlaceholder></div>
              <mat-checkbox class="example-margin"><strong>{{preference.name}}</strong><br />{{preference.description}}</mat-checkbox>
            </div>
          </div>
          <br />
          <h3>Question Editor</h3>
          <div>
            <div>
              <mat-checkbox class="example-margin" formControlName="EditQuestionName"><strong>Edit Question Name</strong><br />Edit question name instead of using the system default values. this can be useful for data analysis.</mat-checkbox>
            </div>
            <div>
              <mat-checkbox class="example-margin" formControlName="EditAnswerOptions"><strong>Edit Answer Options</strong><br />Edit answer options to from the default a,b,c... values chosen, even when it is not a Chat interview.</mat-checkbox>
            </div>
            <div>
              <mat-checkbox class="example-margin" formControlName="EditAnswerOptionFactors"><strong>Edit Answer Option Factors</strong><br />Edit answer option factors that support greater control over the survey routing and with access to the advanced data analysis options you can have better control over your reporting. </mat-checkbox>
            </div>
          </div>
          <br />
          <h3>Data Analysis and Reporting Preferences</h3>
          <div>
            <div>
              <mat-checkbox class="example-margin" formControlName="AccessToAdvancedAnalysisOptions"><strong>Access to Advanced Analysis Options</strong><br />The ability to look at factors from multiple choice type questions.</mat-checkbox>
            </div>
          </div>
        </div>
        <div class="clearBoth">
          <button mat-raised-button color="primary" (click)="updatePreferences()">Update Preferences</button>&nbsp;
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="isCurrentUser || isAdminUser" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="simple-row">
              <mat-icon class="mini-fab">attach_money</mat-icon><span class="user-details-expansion-panel-title">Billing &amp; Plans</span>
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngIf="isAdminUser">
          <div *ngIf="!isCreditCustomer" class="fieldRowWithPadding">
            <div>
              This user is currently on a "Pay as You Go" plan. Their balance is £{{balance | number:'1.2'}}&nbsp;
            </div>
            <div>
              <button mat-raised-button color="primary" (click)="viewTransactions()">View Transactions</button>
            </div>
          </div>
          <div *ngIf="isCreditCustomer" class="fieldRowWithPadding">
            <div>
              This user is a credit customer. Use our billing service to keep track of your expenses.
            </div>
            <div>
              <button mat-raised-button color="primary" (click)="viewTransactions()">View Transactions</button>
            </div>
          </div>
          <div>
            <span>Billing Type: </span>&nbsp;&nbsp;
            <mat-button-toggle-group #group="matButtonToggleGroup" single formControlName="BillingType">
              <mat-button-toggle value="Credit">Credit</mat-button-toggle>
              <mat-button-toggle value="Cash">Cash</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <br />
          <h3>Cint Panel Pricing Model Setup</h3>
          <div class="fieldRowWithPadding">
            <div>
              <span>Model: </span>&nbsp;&nbsp;
              <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="PanelPricingModel">
                <mat-button-toggle value="1" [checked]="user.PanelPricingModel === 1">Percentage</mat-button-toggle>
                <mat-button-toggle value="2" [checked]="user.PanelPricingModel === 2">Fixed Amount</mat-button-toggle>
              </mat-button-toggle-group>
            </div>&nbsp;&nbsp;
            <mat-form-field appearance="outline" class="user-details-invite-role-div">
              <mat-label>Amount</mat-label>
              <input matInput placeholder="Amount" formControlName="PanelAmount">
            </mat-form-field>
          </div>
          <br />
          <h3>Survey Pricing Model Setup</h3>
          <p>Cint cost for the complete in addition to the panel cost. If it is zero then it is based purely on panel cost if a Cint Panel is used.</p>
          <div>
            <mat-form-field appearance="outline" class="user-details-invite-role-div">
              <mat-label>Cint Complete</mat-label>
              <input matInput placeholder="Complete" formControlName="CintCompleteCost">
            </mat-form-field>
          </div>
          <p>Cost for the complete when your own panel is used.</p>
          <div>
            <mat-form-field appearance="outline" class="user-details-invite-role-div">
              <mat-label>Complete</mat-label>
              <input matInput placeholder="Amount" formControlName="CompleteCost">
            </mat-form-field>
          </div>
          <div>
            <button mat-raised-button color="primary" (click)="updatePricingStructure()">Update Pricing Structure</button>&nbsp;
          </div>
        </div>
        <div *ngIf="isCurrentUser && !isAdminUser">
          <div *ngIf="!isCreditCustomer">
            You are currently on a "Pay as You Go" plan. Your balance is £{{balance | number:'1.2'}}
          </div>
          <div *ngIf="isCreditCustomer">
            You are a credit customer. Use our billing service to keep track of your expenses.
          </div>
          <div class="clearBoth">
            <button mat-raised-button class="mt-5" color="primary" (click)="viewTransactions()">View Transactions</button>&nbsp;
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="isOrganization" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="simple-row">
              <mat-icon class="mini-fab">group</mat-icon><span class="user-details-expansion-panel-title">Members</span>
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <mat-tab-group #membership mat-stretch-tabs="false" mat-align-tabs="start" [(selectedIndex)]="selectedMembershipIndex">
            <mat-tab *ngIf="getMembersForOrg().length > 1" label="Members">
              <invitations #membershiplist show="members" [organization]="currentOrganization"></invitations>
            </mat-tab>
            <mat-tab *ngIf="getInvitesForOrg()" label="Invitations">
              <invitations #invitations show="invitations" [organization]="currentOrganization"></invitations>
              <div *ngIf="getInvitesForOrg().length == 0">
                <p>
                  &nbsp;
                </p>
                <h3>Invite Your First Member</h3>
                <p>
                  Find prople already using DIY Surveys or use an email address.
                </p>
              </div>
              <invite-people [invites]="invites"></invite-people>
              <div>
                <button mat-raised-button color="primary" [disabled]="inviting || invites.length == 0" (click)="invitePeople()">{{ inviting ? 'Inviting...' :'Invite'}}</button>&nbsp;
                <button *ngIf="isAdminUser" mat-raised-button color="primary" [disabled]="inviting || invites.length == 0" (click)="addPeople()">{{ inviting ? 'Adding...' :'Add'}}</button>&nbsp;
              </div>
            </mat-tab>
          </mat-tab-group>
          <div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="!isOrganization" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="simple-row">
              <mat-icon class="mini-fab">corporate_fare</mat-icon><span class="user-details-expansion-panel-title">Organizations</span>
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <p>
            Here you will find a list of organizations you are a member of, but do not manage.
          </p>
          <app-organizations [displayTitle]="false"></app-organizations>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="simple-row">
              <mat-icon class="mini-fab">http</mat-icon><span class="user-details-expansion-panel-title">Tiny URL's</span>
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-tinyurls [userId]="userId"></app-tinyurls>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="isDeveloper" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="simple-row">
              <mat-icon class="mini-fab">token</mat-icon><span class="user-details-expansion-panel-title">Access Tokens</span>
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <div>
            <p>
              Access Tokens can be used for accessing the platform through the API. For more information <a href="https://apidocs.diysurveys.com/" target="_blank">Click Here</a>.
            </p>
            <h3>Basic Access Tokens</h3>
            <p>
              Basic access tokens provides access to the DIY Surveys API functionality. They can be used instead of a username and password
              for DIY Surveys over HTTPS using Basic Authentication.
            </p>
            <app-keys keyType="basic" [userId]="userId"></app-keys>
            <p>&nbsp;</p>
            <h3>HMAC Access Tokens</h3>
            <p>
              HMAC access tokens provide a greater level of security to the DIY Surveys API functionality.
            </p>
            <app-keys keyType="hmac" [userId]="userId"></app-keys>
            <p>&nbsp;</p>
            <div *ngIf="isDIYTestersUser">
              <h3>WhatsApp Access Token</h3>
              <div *ngIf="whatsAppStatus != 3">
                <p *ngIf="whatsAppStatus == -1">
                  In order to attach your WhatsApp Business Account to the DIY Surveys WhatsApp Service for this account/organization you need request a WhatsApp Access Token.
                </p>
                <p *ngIf="whatsAppStatus == 0">
                  You have completed the WhatsApp Application Form and it is waiting for approval.
                  <a *ngIf="isCurrentUser" [routerLink]="'/whatsapp/0/whatsapp/' + currentApplication.OwnerId + '/editprofile'">Click here</a> to review the Application.
                  <a *ngIf="!isCurrentUser" [routerLink]="'/whatsapp/0/whatsapp/' + currentApplication.OwnerId + '/user'">Click here</a> to review the Application.
                </p>
                <p *ngIf="whatsAppStatus == 1">
                  Your application has been approved and DIY Surveys is connecting your account to your WhatsApp Business Account.<br />
                  You will be notified as soon as this complete.
                </p>
                <p *ngIf="whatsAppStatus == 2">
                  Your application has been rejected with the basic feedback:<br />
                  {{currentApplication?.Data.StatusMessage}}<br /><br />
                  For more information please contact the Client Services Team.
                </p>
                <div class="simple-row">
                  <div *ngIf="whatsAppStatus == -1">
                    <button mat-raised-button color="primary" (click)="requestWhatsAppAccessToken()">Request WhatsApp Access Token</button>
                    &nbsp;
                  </div>
                  <div *ngIf="isAdminUser && key == null">
                    <button mat-raised-button color="primary" (click)="createWhatsAppAccessToken()">Create WhatsApp Access Token</button>
                  </div>
                </div>
              </div>
              <div *ngIf="whatsAppStatus == 3 || (isAdminUser && key != null)">
                <p>
                  Your WhatsApp Access Token is in place so you can create and test templates.
                </p>
                <div class="simple-row">
                  <div *ngIf="isCurrentUser">
                    <a mat-raised-button color="primary" [routerLink]="'/communications'">Create &amp; Test WhatsApp Templates</a>
                    &nbsp;
                  </div>
                  <div *ngIf="isAdminUser">
                    <button mat-raised-button color="primary" (click)="createWhatsAppAccessToken()">View WhatsApp Access Token</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="isDeveloper" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="simple-row">
              <mat-icon class="mini-fab">webhook</mat-icon><span class="user-details-expansion-panel-title">Web Hooks</span>
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-webhooks [userId]="userId"></app-webhooks>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="isDeveloper" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="simple-row">
              <mat-icon class="mini-fab">key</mat-icon><span class="user-details-expansion-panel-title">Keywords</span>
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-keywords></app-keywords>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="!isOrganization" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="simple-row">
              <mat-icon class="mini-fab">warning</mat-icon><span class="user-details-expansion-panel-title">Handle with care</span>
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <div class="user-details-delete-acct-container">
            <h3>Delete this account</h3>
            <div *ngIf="organizations?.length == 1">
              <p>Once an account has been deleted it will be gone forever!</p>
            </div>
            <div *ngIf="organizations?.length > 1">
              <p>
                Your account is currently an owner of these organizations: <span *ngFor="let org of organizations; let i = index"><strong *ngIf="i > 1">&nbsp;,</strong><strong *ngIf="i > 0">{{org.Name}}</strong></span>
              </p>
              <p>
                You must remove yourself, transfer ownership or delete these organizations before you can delete your user.
              </p>
            </div>
            <button mat-flat-button color="warn" [disabled]="!isAdminUser && organizations?.length > 1" (click)="deleteAccount()">Delete Account</button>&nbsp;
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="isOrganization" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="simple-row">
              <mat-icon class="mini-fab">warning</mat-icon><span class="user-details-expansion-panel-title">Handle with care</span>
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <div>
            <div class="user-details-delete-acct-container">
              <div class="user-details-delete-org-container">
                <div class="user-details-delete-org-header-div">
                  <h3>Delete this organization</h3>
                  <span>Once an organization has been deleted it will be gone forever!</span>&nbsp;&nbsp;
                </div>
                <div align="right" class="user-details-delete-org-button-div">
                  <button mat-flat-button color="warn" (click)="deleteOrganization()">Delete Organization</button>&nbsp;
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="user-details-transfer-org-container">
                <div class="user-details-delete-org-header-div">
                  <h3>Transfer this organization</h3>
                  <span>Transfer your organization to another user</span>&nbsp;&nbsp;
                </div>
                <div class="user-details-delete-org-button-div" align="right">
                  <button mat-flat-button color="warn" (click)="transferOrganization()">Transfer Organization</button>&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
  <div class="user-details-bottom-padding"></div>
</div>
<ng-template #loading>
  <div class="loading-container">
    <div class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </div>
  </div>
</ng-template>
<ng-template #loadingSurveys>
  <div>
    <div class="users-loading-content">
      <div class="users-spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="users-spinner-text"> Gathering stats...</div>
    </div>
  </div>
  <br /><br />
</ng-template>
<ng-template #loadingAccounts>
  <div>
    <div class="user-details-acctLoading-content">
      <div class="users-spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="users-spinner-text"> Loading Organizations...</div>
    </div>
  </div>
  <br /><br />
</ng-template>
<ng-template #deleteOrgDialog>
  <div class="user-details-dialog-container">
    <div class="leftFloat">
      <h3>Delete Organization</h3>
    </div>
    <div class="rightFloat">
      <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
        <mat-icon class="user-details-dialog-close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="user-details-dialog-content">
    <div class="alert alert-danger" role="alert">
      <span>Deleting the Organization will mean that all surveys and panels and associated data owned by the organization will also be deleted forever!</span>
    </div>
    <div [formGroup]="deleteForm">
      <div>
        Before you delete, please consider:
        <ul>
          <li>Exporting your survey scripts or assigning them to a different user/organization.</li>
          <li>Exporting all your results</li>
          <li>Exporting any panel data</li>
        </ul>
      </div>
      <div>
        Please type <strong>{{currentOrganization.Name}}</strong> to confirm the deletion
      </div>
      <mat-form-field appearance="outline" class="longField">
        <input matInput formControlName="Delete">
      </mat-form-field>&nbsp;&nbsp;
    </div>
    <button mat-flat-button color="warn" [disabled]="!deleteValid" (click)="confirmDeleteOrganization()">Delete Organization</button>&nbsp;
  </div>
</ng-template>
<ng-template #deleteUserDialog>
  <div class="user-details-dialog-container">
    <div class="leftFloat">
      <h3>Delete Account</h3>
    </div>
    <div class="rightFloat">
      <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
        <mat-icon class="user-details-dialog-close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="user-details-dialog-content">
    <div class="alert alert-danger" role="alert">
      <span>Deleting the Account will mean that all surveys and panels and associated data owned by the account will also be deleted forever!</span>
    </div>
    <div [formGroup]="deleteUserForm">
      <div>
        Before you delete, please consider:
        <ul>
          <li>Transferring any organizations you own to a different user.</li>
          <li>Exporting your survey scripts or assigning them to a different user/organization.</li>
          <li>Exporting all your results</li>
          <li>Exporting any panel data</li>
        </ul>
      </div>
      <div>
        Please type <strong>{{user.UserName}}</strong> to confirm the deletion
      </div>
      <mat-form-field appearance="outline" class="longField">
        <input matInput formControlName="Delete">
      </mat-form-field>&nbsp;&nbsp;
    </div>
    <button mat-flat-button color="warn" [disabled]="!deleteUserValid" (click)="confirmDeleteAccount()">Delete Account</button>&nbsp;
  </div>
</ng-template>
<ng-template #invitationDialog>
  <div class="user-details-dialog-container">
    <div class="leftFloat">
      <h3>Confirm {{ addOrInvite ? 'Additions' : 'Invitations' }}</h3>
    </div>
    <div class="rightFloat">
      <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
        <mat-icon class="user-details-dialog-close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="user-details-dialog-content">
    <div class="alert alert-success" role="alert">
      <span>Give them the appropriate role in the organization</span>
    </div>
    <div>
      <div *ngFor="let invite of invites" class="user-details-invitation-container">
        <div class="user-details-invite-username-div">
          {{invite.UserName}}
        </div>
        <div>
          <mat-form-field appearance="outline" class="user-details-invite-role-div">
            <mat-label>Role</mat-label>
            <mat-select [(value)]="invite.Role">
              <mat-option [value]="0">Member</mat-option>
              <mat-option [value]="1">Owner</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div>
        <button mat-raised-button color="primary" (click)="dialogRef.close('yes')">{{ addOrInvite ? 'Add People' : 'Send Invitation' + (invites.length > 1 ? 's' : '') }}</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #profileDialog>
  <div>
    <div class="user-details-dialog-container">
      <div class="leftFloat">
        <h3>Crop your new profile picture</h3>
      </div>
      <div class="rightFloat">
        <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
          <mat-icon class="user-details-dialog-close-icon">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="user-details-profile-image-container">
      <div>
        <div class="user-details-imageCrop-container" *ngIf="imageChangedEvent">
          <image-cropper #imageCropper [imageChangedEvent]="imageChangedEvent"
                         [maintainAspectRatio]="true"
                         [aspectRatio]="10 / 10"
                         format="png"
                         [resizeToWidth]="150"
                         [resizeToHeight]="150"
                         (imageCropped)="imageCropped($event)"
                         (imageLoaded)="imageLoaded()"
                         (cropperReady)="cropperReady()"
                         (loadImageFailed)="loadImageFailed()"></image-cropper>
        </div>
      </div>
      <div class="user-details-profile-image-divider">
        <mat-divider></mat-divider>
      </div>
      <div class="user-details-profile-image-divider">
        <button mat-raised-button color="primary" (click)="dialogRef.close('yes')">Set profile picture</button>
      </div>
    </div>
  </div>
</ng-template>
