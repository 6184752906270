<mat-toolbar [class]="checkStyle()" class="mat-toolbar-container-noshadow-new">
  <a [routerLink]="'/'"><img src="assets/img/logo.svg" class="toolbar-logo" (click)="scrollTo('home')" /></a>&nbsp;&nbsp;
  <div *ngIf="toolbarType == 'both'">
    <button mat-button (click)="scrollTo('home')">Surveys</button>&nbsp;&nbsp;
    <button mat-button (click)="scrollTo('step2')">Buy an Audience</button>&nbsp;&nbsp;
    <button mat-button (click)="scrollTo('marketing')">Marketing Solutions</button>&nbsp;&nbsp;
    <button mat-button (click)="scrollTo('enterprise')">Enterprise</button>&nbsp;&nbsp;
  </div>
  <span class="toolbar-spacer"></span>
  <div *ngIf="toolbarType == 'both'" class="toolbar-header-right-container" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
    <a class="a" href="mailto:hello@diysurveys.com">hello&#64;diysurveys.com</a>&nbsp;&nbsp;
    <button mat-raised-button color="primary" [routerLink]="'/newlogin'">
      LOG IN
    </button>&nbsp;&nbsp;
    <button mat-raised-button color="accent" [routerLink]="'/newsignup'">SIGN UP</button>
  </div>
  <div *ngIf="toolbarType == 'signup'" class="toolbar-header-right-container" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
    New to DIY Surveys?&nbsp;&nbsp;
    <button mat-raised-button color="accent" [routerLink]="'/newsignup'">SIGN UP</button>
  </div>
  <div *ngIf="toolbarType == 'login'" class="toolbar-header-right-container" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
    Already have an account?&nbsp;&nbsp;
    <button mat-raised-button color="primary" [routerLink]="'/newlogin'">LOG IN</button>
  </div>

</mat-toolbar>
<div class="toolbar-handset-container">
  <div class="toolbar-handset">
    <a [routerLink]="'/newhome'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
  </div>
  <div class="login-signup-div" [class]="(isHandset$ | async) ? '' : 'noDisplay'">
    <button mat-raised-button color="primary" [routerLink]="'/newlogin'" class="signup-btn-handset">
      LOG IN
    </button>&nbsp;&nbsp;
    <button mat-raised-button color="accent" [routerLink]="'/newsignup'" class="signup-btn-handset">
      SIGN UP
    </button>
  </div>
</div>
