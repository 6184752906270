<mat-toolbar [class]="checkStyle()" class="mat-toolbar-container-noshadow">
  <a [routerLink]="'/'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
  <span class="toolbar-spacer"></span>
  <div class="toolbar-header-right-container" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
    New to DIY Surveys?&nbsp;&nbsp;
    <button mat-raised-button color="accent" [routerLink]="'/newsignup'">SIGN UP</button>
  </div>
</mat-toolbar>
<div class="toolbar-handset-container">
  <div class="toolbar-handset">
    <a [routerLink]="'/'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
  </div>
  <div class="login-signup-div" [class]="(isHandset$ | async) ? '' : 'noDisplay'">
    <button mat-raised-button color="primary" [routerLink]="'/signup'" class="signup-btn-handset">
      LOGIN
    </button>
  </div>
</div>
<div #body class="body" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
  <div class="header" style="width:100%">
    <div class="headerContent">
      <div style="width:50%;margin:0 auto;text-align:center">
        <div *ngIf="enterDetails" [@fadeInOnEnter] [@fadeOutOnLeave] style="display:flex;flex-flow:column">
          <h1><span style="font-size:2rem">Log into your account</span></h1>
          <p>

          </p>
          <form [formGroup]="formGroup">
            <div style="margin:5px auto;width:50%;">
              <div *ngIf="getError() != ''" [@fadeInOnEnter] [@fadeOutOnLeave] class="alert alert-danger" role="alert">
                <div style="font-size:0.75rem">{{getError()}}</div>
              </div>
            </div>
            <div>
              <mat-form-field appearance="outline" class="halfWidth">
                <mat-label>Email/Username</mat-label>
                <input matInput placeholder="Email/Username" formControlName="email">
                <span *ngIf="getError() == ''"></span>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" class="password-field halfWidth">
                <mat-label>Password</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" placeholder="Password" formControlName="password">
                <mat-icon class="reveal" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </div>
            <div>
              <div style="font-size:0.60rem;width:100%">
                <div style="text-align:right;width:75%">
                  <a [routerLink]="'/forgotpassword'">Forgot Password?</a>
                </div>
              </div>
            </div>
            <div style="margin-top:20px">
              <button mat-raised-button type="submit" color="primary" (click)="loginProcess()">LOG IN</button>
            </div>
          </form>
          <div style="margin:5px auto;width:50%;">
            <div class="new-register-or-separator">
              <span>Or Log in with</span>
            </div>
            <div style="width: 100%; margin-top:-20px; height: 50px; display: flex; flex-flow: row; align-items: center;">
              <div style="width: 50%; margin: 0 -40px 0 18%;">
                <asl-google-signin-button type="icon" size="medium"></asl-google-signin-button>
              </div>
              <div class="social-login-separator">
              </div>
              <a mat-stroked-button href="#" (click)="logInWithFacebook()" style="width: 50%; border: none; height: 100%; padding-top: 7px;">
                <img src="assets/img/icon-facebook.png" />
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="loggingIn" [@fadeInOnEnter] [@fadeOutOnLeave] style="display:flex;flex-flow:column">
          <h2><span style="font-size:2rem">Logging into your account</span></h2>
          <div style="margin: 5px auto">
            <mat-spinner></mat-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <app-newfooter></app-newfooter>
  </div>
</div>
<ng-template #loggingInDialog>
  <div class="user-details-profile-image-container" style="width:200px;height:100%;vertical-align:middle;">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Logging in...</div>
    </span>
  </div>
</ng-template>
