import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, tap } from 'rxjs';
import { CommsService, WhatsAppApplication } from '../../comms.Service';

@Component({
  selector: 'app-manage-whatsapp',
  templateUrl: './manage-whatsapp.component.html',
  styleUrls: ['./manage-whatsapp.component.css']
})
export class ManageWhatsappComponent {
  tempApplications: Observable<any>;
  Applications: WhatsAppApplication[] = [];
  dataSource = new MatTableDataSource(this.Applications);
  displayedColumns: string[] = ['ApplicationId', 'Status', 'Created', 'Amended', 'CompanyName', 'BrandName'];

  constructor(public dialog: MatDialog,
    public translate: TranslateService,
    private commsService: CommsService,
    private router: Router) {
    this.tempApplications = commsService.getApplications().pipe(tap<WhatsAppApplication[]>(result => {
      this.Applications = result;
      this.dataSource = new MatTableDataSource(this.Applications);
    }));
  }

  ngOnInit(): void {
  }

  status(element: WhatsAppApplication) {
    switch (element.StatusId) {
      case 0:
        return "Pending";
      case 1:
        return "Approved";
      case 2:
        return "Rejected";
      default:
        return "Unknown";
    }
  }

  edit(element: WhatsAppApplication) {
    this.router.navigate(["/whatsapp", 0, "whatsapp", element.OwnerId, "manage"]);
  }


}
