import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError, map, Observable, startWith, Subscription, switchMap, timer, of as observableOf } from 'rxjs';
import { MissionService } from "../MissionService";
import { PanelistProfile, PanelModel, PanelService, PanelSummaryModel } from "../panel.Service";
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarDefaultComponent } from '../snackbar/snackbar-default.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-edit-panel',
  templateUrl: './edit-panel.component.html',
  styleUrls: ['./edit-panel.component.css']
})
export class EditPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  searchKey: string = "";
  noPanelist = true;
  tempRespondents?: Observable<PanelistProfile[]>;
  panelId: string = "";
  displayedColumns: string[] = ['email', 'country', 'mobile', 'firstName', 'lastName', 'actions'];
  subscription: Subscription;
  summary: PanelSummaryModel = new PanelSummaryModel();
  panellists: PanelistProfile[] = [];
  panel: PanelModel;
  total: number;

  sb: MatSnackBarRef<any> | null = null;
  pageSize = 20;
  page: number = 0;
  isRateLimitReached = false;
  private inTimer = false;
  private isLoadingResults = false;

  @ViewChild('table', { static: true }) table: any | undefined;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(public dialog: MatDialog,
    private panelService: PanelService,
    private missionService: MissionService, 
    private router: Router,
    private snackbar: MatSnackBar,
    private actRoute: ActivatedRoute) {
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.onSubmit();
        }
      });
  }

  ngOnInit(): void {
    this.panelId = this.actRoute.snapshot.params.id;
    this.getPanellists();
  }

  ngAfterViewInit(): void {
    this.paginator?.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
          this.page = (this.paginator?.pageIndex ?? 0) + 1;
          /*this.surveyService.getSurveyTotals().subscribe(
            result => {
              this.totals = result;
              this.checkTabs();
            });*/
          return this.panelService.getPanelists(this.panelId, this.searchKey, this.page, this.pageSize)
            .pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;
          this.sb?.dismiss();

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          return data;
        }),
      )
      .subscribe(data => (this.panellists = data));
  }

  initForm() {
  }

  getPanel() {
    this.panelService.getPanel(this.panelId).subscribe(
      result => {
        this.panel = result;
        this.summary = result.Summary;
        this.total = this.panel.PanellistCount;
      }
    )
  }

  getPanellists() {
    this.getPanel();
    this.page = (this.paginator?.pageIndex ?? 0) + 1;
    if (this.page > 1 && (this.paginator.pageIndex * this.paginator?.pageSize) >= this.total) {
      this.page--;
    }

    if (this.paginator != undefined) {
      this.paginator.pageIndex = this.page - 1;
    }

    this.getPanellistFromServer();
  }

  getPanellistFromServer() {
    this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
    this.panelService.getPanelists(this.panelId, this.searchKey, this.page, this.pageSize).subscribe(
      result => {
        if (result.length == 0) {
          this.paginator?.pageIndex == 0 ? 0 : this.page;
          this.panellists = [];
        }
        else {
          this.panellists = result;
        }

        this.sb?.dismiss();
      });
  }

  onSearchClear(event: Event) {
    this.searchKey = "";
    // this.dataSource.filter = this.searchKey;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (filterValue === "") {
      return;
    }
    // this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private dismissSnackBar() {
    this.sb?.dismiss();
  }

  addRespondents(): void {
    this.router.navigate(['uploadRespondents-template', this.panelId, 'panel']);
    this.dismissSnackBar();
  }

  deleteRespondent(panellist: PanelistProfile) {
    this.panelService.deletePanellist(this.panelId, panellist.PanellistID.toString()).subscribe(
      result => {
        this.getPanellists();
      }
    )
  }

  onSubmit() {
    this.router.navigate(['/respondents']);
    this.dismissSnackBar();
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  edit(panellist: PanelistProfile) {
    this.router.navigate(['edit-respondent', this.panelId, panellist.PanellistID, 'panelist']);
    this.dismissSnackBar();
  }
}


