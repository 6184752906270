import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PanelSummaryComponent } from '../../panels/panel-summary/panel-summary.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MissionService } from '../../MissionService';
import { Subscription } from 'rxjs';
import { PanelistProfile, PanelModel, PanelService } from '../../panel.Service';
import { ProcessErrorModel, ProcessService, ProcessServiceModel } from '../../process.Service';

@Component({
  selector: 'app-add-upload-respondent',
  templateUrl: './add-upload-respondent.component.html',
  styleUrls: ['./add-upload-respondent.component.css']
})
export class AddUploadRespondentComponent implements OnInit {
  process: ProcessServiceModel = new ProcessServiceModel();
  isPanel: boolean = false;
  uploadCard: boolean = false;
  individualCard: boolean = false;
  uploadDivCancelSection: boolean = true;
  subscription: Subscription;
  panelId: number;
  progress = 0;
  message = "";
  newPanel = false;
  panel: PanelModel = new PanelModel();
  errors: ProcessErrorModel[] = [];

  selectedFile: File | null = null;

  uploadFormGroup: FormGroup = new FormGroup({});
  individualFormGroup: FormGroup = new FormGroup({});

  isDisabled: boolean = true;

  file: File | null = null;
  arrayBuffer: any;
  fileList: any;
  excelData: [][] = [];

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef | undefined;
  @ViewChild('panelSummary') panelSummary: PanelSummaryComponent | undefined;
  
  @Output() panellistSaveEvent = new EventEmitter();

  @Output() cancelAddPanellistEvent = new EventEmitter();
  @Output() cancelUploadEvent = new EventEmitter();
  @Output() clearEvent = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private missionService: MissionService,
    private panelService: PanelService,
    private processService: ProcessService,
    private actRoute: ActivatedRoute) {
    if (actRoute && actRoute.snapshot && actRoute.snapshot.params.type === "panel") {
      this.panelId = Number(actRoute.snapshot.params.id);
      this.isPanel = true;
    }
    else {
      this.panelId = 0;
      this.newPanel = true;
    }

    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.onBackClick();
        }
      });
  }

  ngOnInit(): void {
    this.initForm();
    this.setEmailOrMobileValidators();
    if (this.router.url.includes('/uploadRespondents-template/')) {
      this.uploadDivCancelSection = false;
    }
  }

  initForm() {
    this.uploadFormGroup = new FormGroup({});

    this.individualFormGroup = new FormGroup({
      email: new FormControl("", [Validators.email]),
      mobile: new FormControl('', [Validators.minLength(10)])
    });
  }

  initializeFormGroup() {
    this.individualFormGroup.setValue({
      email: "",
      mobile: ''
    });
  }

  onBackClick() {
    if (this.uploadDivCancelSection) {
      this.router.navigate(["/surveydashboard"]);
    }
    else {
      if (this.panelId == 0) {
        this.router.navigate(["/respondents"]);
      }
      else {
        this.router.navigate(["/edit-respondents", this.panelId, "panel"]);
      }
    }
  }

  setEmailOrMobileValidators() {
    const emailControl = this.individualFormGroup.controls.email;
    const mobileControl = this.individualFormGroup.controls.mobile;
    this.isDisabled = true;

    this.individualFormGroup.controls.email.valueChanges
      .subscribe(email => {
        if (emailControl.valid) {
          emailControl.setValidators([Validators.email]);
          mobileControl.setValidators(null);
          this.isDisabled = false;
        } else if (emailControl.touched && !emailControl.pristine && emailControl.value == "") {
          this.isDisabled = true;
        } else {
          this.isDisabled = true;
        }
      });

    this.individualFormGroup.controls.mobile.valueChanges
      .subscribe(mobile => {

        if (mobileControl.valid) {
          mobileControl.setValidators([Validators.minLength(10)]);
          emailControl.setValidators(null);
          this.isDisabled = false;
        } else if (mobileControl.value == "") {
          this.isDisabled = true;
        } else {
          this.isDisabled = true;
        }
      });
  }

  showUploadCard() {
    this.uploadCard = true;
    this.individualCard = false;
    this.uploadDivCancelSection = false;
  }

  showIndividualCard() {
    this.individualCard = true;
    this.uploadCard = false;
    this.uploadDivCancelSection = false;
  }

  onClickFileInputButton(): void {
    this.fileInput?.nativeElement.click();
  }

  onFileUploaded(event: any) {
    if (!(this.panelId)) {
      // Create an empty Panel to upload into
      this.panelService.addUserPanel(event.originalName).subscribe(
        result => {
          this.panel = result as PanelModel;
          this.panelId = this.panel.PanelID;
          this.processPanel(event);
        })
    }
    else {
      // Upload into a named panel
      this.processPanel(event);
    }
  }

  processPanel(event: any) {
    this.processService.uploadPanel(event.originalName, event.name, "EXCEL", Number(this.panelId)).subscribe(
      result => {
        this.process = result;
      });
  }

  onFileProcessCompleted(event: any) {
    if (event.process.StatusId == 4) {
      this.processService.getProcessErrors(event.process.ProcessId, 1000).subscribe(
        result => {
          let temp = [];
          for (let i = result.length - 1; i > -1; i--) {
            temp.push(result[i]);
          }

          this.errors = temp;
          if (this.errors.length == 0) {
            this.router.navigate(["/edit-respondents", this.panelId, "panel"]);
          }
        }
      );
      return;
    }

    if (this.newPanel) {
      this.panelService.getPanel(this.panelId.toString()).subscribe(
        result => {
          this.panel = result;
          this.onSave();
        }
      )
    }
    else {
      this.router.navigate(["/edit-respondents", this.panelId, "panel"]);
    }
  }

  onCancelUploadClick() {
    this.cancelUploadEvent.emit();
    this.selectedFile = null;
    this.uploadCard = false;
    this.uploadDivCancelSection = false;
  }

  cancelAddPanellistClick() {
    this.cancelAddPanellistEvent.emit();
  }

  onClear() {
    this.clearEvent.emit();
    this.individualFormGroup.reset();
    this.initializeFormGroup();
    this.individualCard = false;
    this.isDisabled = true;
    this.uploadDivCancelSection = false;
  }

  onSave() {
    if (!this.isPanel) {
      this.panellistSaveEvent.emit({
        data: {
          panel: this.panel,
          email: this.individualFormGroup.controls.email.value,
          mobile: this.individualFormGroup.controls.mobile.value
        }
      });
      return;
    }

    let profile = new PanelistProfile();
    profile.Email = this.individualFormGroup.controls.email.value;
    profile.MobileNumber = this.individualFormGroup.controls.mobile.value;
    profile.PanelID = this.panelId;
    this.panelService.addPanelist(profile).subscribe(result => {
      this.onBackClick();
    });
  }

  @Input()
  get newPanelName(): string {
    return this._newPanelName;
  }
  set newPanelName(value: string) {
    this._newPanelName = value;
  }
  private _newPanelName: string = "";
}
