import { EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { EstimateModel, PanelistProfile, PanelModel, PanelService, PanelSummaryModel } from '../panel.Service';
import { JobMasterModel, SurveyModel, SurveyService } from '../survey.Service';
import { AddUploadRespondentComponent } from './add-upload-respondent/add-upload-respondent.component';
import { SelectRespondentService } from './services/select-respondent.service';
import { CintFeasibility } from '../cint.Service';
import { PanelsComponent } from '../panels/panels.component';
import { MatDialog } from '@angular/material/dialog';
import { PanelSelectorComponent } from '../panel-selector/panel-selector.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PanelDialogComponent } from '../panels/panel-dialog/panel-dialog.component';
import { InterviewModel } from '../question.service';
import { PanelPricingComponent } from '../edit-panel/panel-pricing/panel-pricing.component';
import { ProcessService, ProcessServiceModel } from '../process.Service';

@Component({
  selector: 'app-select-respondents-landing-page',
  templateUrl: 'select-respondents-landing-page.component.html',
  styleUrls: ['select-respondents-landing-page.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', maxHeight: '0' })),
      state('expanded', style({ height: '*', })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]

})
export class SelectRespondentsLandingPageComponent implements OnInit {
  panellistProfile: PanelistProfile = new PanelistProfile();
  panellist: PanelistProfile[] = [];
  quota: CintFeasibility = new CintFeasibility();
  panel: PanelModel | null = null;
  panelCount = 0;
  edit = false;
  editType: string = "";

  uploadDiv: boolean = false;
  respondentDiv: boolean = false;

  uploadDivCancelSection: boolean = true;

  individualCard: boolean = false;
  uploadCard: boolean = false;

  isDisabled: boolean = true;

  @Output() secondStepEvent = new EventEmitter();
  @Output() secondStepCintEvent = new EventEmitter();

  @ViewChild('panelSelector') panelSelector: PanelsComponent | undefined;
  @ViewChild('addPanellist') addPanellistForm: AddUploadRespondentComponent | undefined;
  @ViewChild('panelPricing') panelPricing: PanelPricingComponent | undefined;

  constructor(private dialog: MatDialog,
    private processService: ProcessService,
    private surveyService: SurveyService,
    private panelService: PanelService,
    private confirmCintPanelService: SelectRespondentService) {
    this.panelService.panelCount().subscribe(result => {
      this.panelCount = result;
    });
  }


  ngOnInit(): void { }

  initForm() {
  }

  initializeFormGroup() {
    this.addPanellistForm?.individualFormGroup.setValue({
      email: "",
      mobile: ''
    });
  }

  onUploadDivCancelClick() {
    this.uploadDiv = false;
    this.showMainSection = true;
    this.uploadDivCancelSection = true;
  }

  onCancelUpload() {
    this.uploadDiv = false;
    this.showMainSection = true;
    this.uploadDivCancelSection = true;
  }

  uploadDivFunction() {
    this.edit = false;
    this.uploadDiv = true;
    this.showMainSection = false;
  }

  onEdit(isEdit: boolean) {
    if (!this.allowDelete) {
      return;
    }

    this.showMainSection = !isEdit;
  }

  onClear() {
    this.addPanellistForm?.individualFormGroup.reset();
    this.initializeFormGroup();
    this.uploadDiv = false;
    this.showMainSection = false;
    this.individualCard = false;
    this.uploadDivCancelSection = true;
    this.edit = true;
    this.isDisabled = true;
  }

  onPanellistSave(event: any) {
    let data = event.data;
    if (this.survey == null) {
      return;
    }

    if (data.panel && data.panel.PanelID > 0) {
      this.panel = data.panel;
      this.survey.PanelID = data.panel.PanelID;
      this.surveyService.setPanel(this.survey.SurveyID, this.survey.PanelID);
      this.onClear();
    }
    else {
      if (this.addPanellistForm?.individualFormGroup.controls.email.value === "" && this.addPanellistForm.individualFormGroup.controls.mobile.value === "") {
        this.addPanellistForm.individualFormGroup.controls.email.setValidators([Validators.required]);
        this.addPanellistForm.individualFormGroup.controls.mobile.setValidators([Validators.required]);
      } else if (this.addPanellistForm?.individualFormGroup.valid && this.addPanellistForm.individualFormGroup.value !== "") {
        this.panellistProfile.Email = this.addPanellistForm.individualFormGroup.controls.email.value;
        this.panellistProfile.MobileNumber = this.addPanellistForm.individualFormGroup.controls.mobile.value;
          this.panellistProfile.PanelID = this.survey.PanelID;
          this.panelService.addPanellistToNewPanel(this.panellistProfile, this.survey.Name).subscribe(result => {
            if (this.survey != null) {
              this.survey.PanelID = (result as any).PanelID;
              this.panelService.getPanel(this.survey.PanelID.toString()).subscribe(
                result => {
                  this.panel = result;
                });
              this.surveyService.setPanel(this.survey.SurveyID, this.survey.PanelID);
            }
          });
        this.onClear();
      }
    }
  }

  buyDivFunction() {
    this.buyAudienceDiv = true;
    this.showMainSection = false;
    this.edit = false;
  }

  respondentDivFunction() {
    this.respondentDiv = true;
    this.showMainSection = false;
    this.edit = false;
  }

  onCloserespondents() {
    this.respondentDiv = false;
    this.showMainSection = true;
    this.edit = true;
  }

  customDataImport() {
    this.processService.getImportProcessesForReference(this._survey.SurveyID).subscribe(
      result => {
        let processes: ProcessServiceModel[] = result;
        if (processes.length == 0) {
          this.newProcess();
        }
        else {
          this.newProcessPanel();
        }
      },
      error => {
        this.newProcess();
      });
  }

  newProcessPanel() {
    this.survey.PanelID = 0;
    this.survey.JobMaster = new JobMasterModel();
    this.panel = new PanelModel();
    this.panel.PanelType = 1;
    this.panel.Summary = new PanelSummaryModel();
    this.panel.Summary.Devices = 1;
    this.panel.Summary.Emails = 1;
    this.panel.SearchTag = "";
    this.panelService.addPanel(this.panel).subscribe(
      result => {
        this.panel = result as PanelModel;
        if (this.survey != null) {
          this.survey.PanelID = this.panel.PanelID;
          this.surveyService.setPanel(this.survey.SurveyID, this.panel.PanelID);
        }
      });
    this.showMainSection = false;
    this.edit = false;

  }

  newProcess() {
    let process = ProcessService.newProcess(this.survey.SurveyID, this.survey.Name, false, true);
    this.processService.saveProcess(process).subscribe(
      result => {
        this.newProcessPanel();
      });
  }


  // ======= Use previous respondents start ========
  showPanels() {
    let dialogRef;
    let opened = false;
    dialogRef = this.dialog.open(PanelDialogComponent, {
      data: { survey: this.survey },
      width: '80%',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        let data = result.data;
        this.panel = data.panel;
        if (this.survey != null) {
          this.survey.PanelID = this.panel?.PanelID ?? 0;
        }
        this.onCloserespondents();
        this.showMainSection = false;
      }
    });
  }

  // ======= Use previous respondents end ========

  // ======== Select & Buy Respondents section start ==========

  buyAudienceDiv: boolean = false;
  audienceCard: boolean = false;
  audienceTemplateCard: boolean = false;
  audienceCancelbtn: boolean = false;
  audiencetemplateCancelbtn: boolean = false;

  showAudience(): boolean {
    this.edit = false;
    if (this.survey != null && this.survey.PanelID > 0) {
      this.panelService.getPanel(this.survey.PanelID.toString()).subscribe(
        result => {
          this.panel = result;
          try {
            this.quota = JSON.parse(this.panel.Quota) as CintFeasibility;
          }
          catch {
            this.quota = new CintFeasibility();
          }
          const dialogRef = this.dialog.open(PanelSelectorComponent, { width: '80%', data: { quota: this.quota, questionCount: 5 } });
          dialogRef.afterClosed().subscribe(result => {
            if (result && result.quota != null) {
              this.quota = result.quota.Feasibility;
              this.onCloseBuyRespondentDiv();
              this.cintPanelConfirmed();
            }

            return false;
          });
        });
    }
    else {
      this.quota = new CintFeasibility();
      const dialogRef = this.dialog.open(PanelSelectorComponent, { width: '80%', data: { quota: this.quota, questionCount: 5 } });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.quota != null) {
          this.quota = result.quota.Feasibility;
          this.onCloseBuyRespondentDiv();
          this.cintPanelConfirmed();
        }

        return false;
      });
    }

    return false;
  }

  showPanelTemplate() {
    this.audienceCard = false;
    this.audienceTemplateCard = true;
    this.buyAudienceDiv = false;
    this.audiencetemplateCancelbtn = true;
  }

  onCloseBuyRespondentDiv() {
    this.buyAudienceDiv = false;
    this.showMainSection = true;
    this.audiencetemplateCancelbtn = false;
    this.audienceCancelbtn = false;
  }

  onCloseAudience() {
    this.buyAudienceDiv = true;
    this.audienceCancelbtn = false;
    this.buyAudienceDiv = true;
    this.audienceCard = false;
  }

  onCloseAudienceTemplate() {
    this.buyAudienceDiv = true;
    this.audiencetemplateCancelbtn = false;
    this.buyAudienceDiv = true;
    this.audienceTemplateCard = false;
  }
  // ======== Select & Buy Respondents section end ==========

  isDistributeYourself: boolean = false;

  callThirdStep() {
    if (this.survey != null) {
      this.survey.PanelID = 0;
    }

    this.isDistributeYourself = true;
    this.confirmCintPanelService.isDistributeYourself.next(this.isDistributeYourself);
    this.secondStepEvent.emit();
  }

  sendYourself() {
    let canEdit = false;
    if (this.survey?.PanelID !== -1) {
      canEdit = true;
    }
    if (this.survey != null) {
      this.survey.PanelID = -1;
      this.surveyService.setPanel(this.survey.SurveyID, this.survey.PanelID);
    }

    this.panel = null;
    this.isDistributeYourself = true;
    this.confirmCintPanelService.isDistributeYourself.next(this.isDistributeYourself);
    this.edit = canEdit;
  }

  confirmPanel() {
    if (this.survey == null) {
      return;
    }

    this.surveyService.setPanel(this.survey.SurveyID, this.survey.PanelID);
    this.respondentDiv = false;
    this.showMainSection = false;
    this.edit = true;
  }

  cintPanelConfirmed() {
    if (this.survey?.PanelID === 0 || !(this.panel) || this.panel.PanelType !== 0) {
      this.panel = new PanelModel();
      this.panel.Name = "Cint";
      this.panel.Description = "Cint";
      this.panel.Remark = "Cint";
      this.panel.PanelType = 0;
      this.panel.SearchTag = "";
      this.panel.Quota = JSON.stringify(this.quota);
      this.panelService.addPanel(this.panel).subscribe(
        result => {
          this.panel = result as PanelModel;
          if (this.survey != null) {
            this.survey.PanelID = this.panel.PanelID;
            this.surveyService.setPanel(this.survey.SurveyID, this.panel.PanelID);
          }
        });
    }
    else {
      this.panel.Quota = JSON.stringify(this.quota);
      this.panelService.updatePanel(this.panel).subscribe();
    }

    this.onCloseAudience();
    this.onCloseAudienceTemplate();

    if (this.panelPricing != undefined) {
      this.panelPricing.checkEstimate(this.panel);
    }

    this.buyAudienceDiv = false;
    this.isConfirmPanel = true;
    this.showMainSection = false;
    this.edit = true;
  }

  cintPanelCancelled() {
    this.onCloseAudience();
  }

  isConfirmPanel: boolean = false;

  templateSelected($event: any) {
    if ($event) {
      this.panel = $event;
      if (this.survey != null) {
        this.survey.PanelID = $event.PanelID;
        this.surveyService.setPanel(this.survey.SurveyID, this.survey.PanelID);
      }

      if (this.panelPricing != undefined) {
        this.panelPricing.fullQuote = null;
      }

      this.onCloseAudience();
      this.onCloseAudienceTemplate();
      this.onCloseBuyRespondentDiv();
      this.isConfirmPanel = true;
      this.showMainSection = false;
      this.edit = true;
    }
  }

  @Input()
  get survey(): SurveyModel | null {
    return this._survey;
  }
  set survey(value: SurveyModel | null) {
    this._survey = value;
    if (this._survey) {
      this.panelService.getPanel(this._survey.PanelID.toString()).subscribe(
        result => {
          this.panel = result;
        });
    }
  }
  private _survey: SurveyModel | null = null;

  @Input()
  get interview(): InterviewModel | null {
    return this._interview;
  }
  set interview(value: InterviewModel | null) {
    this._interview = value;
  }
  private _interview: InterviewModel | null = null;

  @Input()
  get showMainSection(): boolean {
    return this._showMainSection;
  }
  set showMainSection(value: boolean) {
    this._showMainSection = value;
  }
  private _showMainSection: boolean = true;

  @Input()
  get allowEditDelete(): boolean {
    return this._allowEditDelete;
  }
  set allowEditDelete(value: boolean) {
    this._allowEditDelete = value;
  }
  private _allowEditDelete: boolean = true;

  @Input()
  get allowDelete(): boolean {
    return this._allowDelete;
  }
  set allowDelete(value: boolean) {
    this._allowDelete = value;
  }
  private _allowDelete: boolean = true;

  get estimate(): EstimateModel | null {
    if (this.panelPricing == undefined) {
      return null;
    }

    return this.panelPricing.estimate;
  }
}
