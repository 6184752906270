import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fadeInAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, fadeInLeftAnimation, fadeInRightAnimation, rubberBandAnimation } from 'angular-animations';
import { fromEvent, takeUntil, Subject, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { version } from "../../../environments/environment";
import { FacebookLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { AuthenticationService, RegistrationModel } from '../../authentication.Service';
import { UserService } from '../../user.Service';

@Component({
  selector: 'app-newsignup',
  templateUrl: './newsignup.component.html',
  styleUrl: './newsignup.component.css',
  animations: [
    fadeInOnEnterAnimation({ duration: 1000 }),
    fadeOutOnLeaveAnimation({ duration: 500 }),
    fadeInAnimation({ anchor: 'enter', duration: 1000 }),
    fadeInAnimation({ anchor: 'errorFadein', duration: 1000, delay: 100 })
  ]
})
export class NewsignupComponent implements OnInit, AfterViewInit {
  [x: string]: any;
  start: boolean = false;
  fadeInLeftState = false;
  formGroup: FormGroup;
  p1: boolean = false;
  p2: boolean = false;
  p3: boolean = false;

  destroy = new Subject();

  destroy$ = this.destroy.asObservable();
  top: number = 0;

  currentVersion: string;
  currentYear = new Date().getFullYear();

  emailValid: boolean = false;
  nameValid: boolean = false;
  passwordValid: boolean = false;
  passwordValid2: boolean = true;

  alreadyExists: boolean = false;
  hasErrors: boolean = false;
  errorMessages: string[] = [];
  registered: boolean = false;
  submitted: boolean = false;

  showName: boolean = false;
  showPassword: boolean = false;
  showPassword2: boolean = false;
  showSignup: boolean = false;
  hide: boolean = true;
  hide2: boolean = true;

  formReady: boolean = false;

  @ViewChild("name") public name: ElementRef;
  @ViewChild("password") public password: ElementRef;
  @ViewChild("password2") public password2: ElementRef;

  constructor(private breakpointObserver: BreakpointObserver, private router: Router,
    private actRoute: ActivatedRoute,
    private userService: UserService,
    private socialService: SocialAuthService,
    private authenticationService: AuthenticationService
  ) {
    this.currentVersion = version;
    this.errorMessages.push("");
    this.errorMessages.push("");
    this.errorMessages.push("");
    this.errorMessages.push("");
    this.formGroup = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
      name: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
      password2: new FormControl("", [Validators.required])
    });

    if (this.actRoute.snapshot.params.id != undefined) {
      this.formGroup.controls.email.setValue(this.actRoute.snapshot.params.id);
      this.checkChanges();
    }

    this.formGroup.valueChanges.subscribe(x => {
      this.checkChanges();
    });
    this.formReady = true;
  }

  setFocus(elementName: string, valid: boolean) {
    if (!valid) {
      return;
    }

    let element = null;
    switch (elementName) {
      case "name":
        if (this.formGroup.controls.email.valid) {
          this.userService.checkUsername(this.formGroup.controls.email.value).subscribe(
            result => {
              this.hasErrors = true;
              this.errorMessages[0] = "The email address is either invalid or already in use";
              this.alreadyExists = true;
              this.emailValid = false;
              return;
            },
            error => {
              this.showName = true;
              this.alreadyExists = false;
              setTimeout(() => {
                while (true) {
                  if (this.name != undefined) {
                    this.name?.nativeElement.focus();
                    return;
                  }
                }
              });
            });
        }

        break;
      case "password":
        this.showPassword = true;
        setTimeout(() => {
          while (true) {
            if (this.password != undefined) {
              this.password?.nativeElement.focus();
              return;
            }
          }
        });
        break;
      case "password2":
        this.showPassword2 = true;
        setTimeout(() => {
          while (true) {
            if (this.password2 != undefined) {
              this.password2?.nativeElement.focus();
              return;
            }
          }
        });
        break;
    }

    return;
  }

  checkChanges() {
    if (!this.formGroup.controls.email.valid) {
      this.hasErrors = true;
      if (this.formGroup.controls.email.value.length > 5) {
        this.errorMessages[0] = "The email address is either invalid or already in use";
      }

      if (this.formGroup.controls.email.value.length < 6) {
        this.errorMessages[0] = '';
      }


      this.emailValid = false;
    }
    else {
      this.hasErrors = false;
      this.errorMessages[0] = "";
      this.emailValid = true;
    }

    if (this.showName && !this.formGroup.controls.name.valid) {
      this.hasErrors = true;
      this.errorMessages[1] = "Please supply a name that you would like to known by";
      this.nameValid = false;
    }
    else {
      this.errorMessages[1] = "";
      this.nameValid = this.showName;
    }

    if (this.showPassword && !this.formGroup.controls.password.valid) {
      this.hasErrors = true;
      this.errorMessages[2] = "Please supply a valid password";
      this.passwordValid = false;
    }
    else {
      this.errorMessages[2] = "";
      this.passwordValid = this.showPassword;
    }

    if (this.showPassword2) {

      if (!this.formGroup.controls.password2.valid) {
        this.hasErrors = true;
        this.errorMessages[3] = "Please supply a valid password";
        this.passwordValid2 = false;
      }
      else if (this.formGroup.controls.password.value != this.formGroup.controls.password2.value) {
        this.hasErrors = true;
        this.errorMessages[3] = "Both the password and confirm Password must match";
        this.passwordValid2 = false;
      }
      else {
        this.errorMessages[3] = "";
        this.passwordValid2 = this.showPassword2;
      }
    }
    else {
      this.errorMessages[3] = "";
      this.passwordValid2 = this.showPassword2;
    }

    return this.checkErrors();
  }

  checkErrors(): boolean {
    let he = false;
    for (let i = 0; i < this.errorMessages.length; i++) {
      if (this.errorMessages[i] != "") {
        he = true;
        break;
      }
    }

    this.hasErrors = he;
    return true;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnDestroy(): void {
    this.destroy.next(this.destroy$);
  }

  ngAfterViewInit(): void {
    if (this.actRoute.snapshot.params.id != undefined) {
      this.formGroup.controls.email.setValue(this.actRoute.snapshot.params.id);
      this.checkChanges();
      if (this.emailValid) {
        this.showName = true;
      }
    }

    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$)).subscribe((e: Event) => {
      this.top = this.getYPosition(e);
    });

    Promise.resolve(null).then(() => {
      this.start = true;
    });
  }

  canSignUp(): boolean {
    return false;
  }

  logInWithFacebook(): boolean {
    this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID);
    return false;
  }

  checkStyle(): string {
    if (this.top > 5) {
      return 'mat-elevation-z2';
    }

    return '';
  }

  ngOnInit(): void {
  }

  checkName(event: any) {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.errorMsg = "";
      this.success = false;

      const name = this.formGroup.controls.email.value;
      if (!name) {
        return;
      }
      if (name.trim().length === 0) {
        return;
      }

      this.testName(name);
    }, 500);
  }

  testName(name: string) {
  }

  submitForm(): boolean {
    let model: RegistrationModel = new RegistrationModel();
    model.email = this.formGroup.controls.email.value;
    let names = this.formGroup.controls.name.value.split(' ');
    model.firstname = names[0];
    let last = '';
    for (let i = 1; i < names.length; i++) {
      last += " " + names[i];
    }

    model.lastname = last;
    model.password = this.formGroup.controls.password.value;
    model.username = model.email;
    model.tandc = true;
    this.registered = false;
    this.submitted = true;
    this.authenticationService.register(model).subscribe(
      result => {
        this.registered = true;
        this.submitted = false;
      },
      error => {
        if (error.status === 404) {
          this.errorMessages[0] = "The Platform is not currently available for registration, please come back and try again";
        }
        else if (error.status === 400) {
          // cannot register
          this.errorMessages[0] = "Unable to register at this time please check the email address";
        }
        else if (error.status === 409) {
          // Duplicate details
          this.errorMessages[0] = "These details may already be in use";
        } else {
          console.error(error.message);
          this.errorMessages[0] = error.message;
        }

        this.checkErrors();
        this.submitted = false;
      });

    return false;
  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  getYPosition(e): number {
    return e.target.scrollingElement.scrollTop;
  }

  startForFree() {
    this.start = !this.start;
  }

  onAppear() {
    let a = 0;
  }

}
