<div class="fullWidth mt-10">
  <div *ngIf="survey?.SurveyID ?? 0 > 0" class="simple-row">
    &nbsp;
    <button mat-stroked-button [matMenuTriggerFor]="ioMenu">
      <mat-icon>expand_more</mat-icon>
      <span>Create</span>
    </button>&nbsp;
    <button mat-stroked-button (click)="showImportDataDefinition()">
      <span>Import</span>
    </button>&nbsp;
    <button mat-icon-button (click)="getProcesses()">
      <mat-icon>refresh</mat-icon>
    </button>
    <mat-menu #ioMenu="matMenu">
      <button mat-menu-item (click)="newProcess(true, false)">
        <span>Export Data...</span>
      </button>
      <button mat-menu-item (click)="newProcess(true, true)">
        <span>Automate Data Export...</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="newProcess(false, false)">
        <span>Import Data...</span>
      </button>
      <button mat-menu-item (click)="newProcess(false, true)">
        <span>Automate Data Import...</span>
      </button>
    </mat-menu>
  </div>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let detail of details; let i = index" hideToggle [expanded]="i == selectedIndex">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">{{detail.icon}}</mat-icon><span class="user-details-expansion-panel-title">{{detail.name}}&nbsp;{{detail.processes?.length > 0 ? '(' + detail.processes?.length + ')' : ''}}</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="detail.processes?.length < 1">
        There is no {{detail.name}} definitions
      </div>
      <app-process-definition *ngFor="let process of detail.processes" [survey]="survey" [interview]="interview" [index]="i" [process]="process" (closed)="processClosed($event)"></app-process-definition>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<ng-template #importDataDialog>
  <div class="fullWidth dialog-title">
    <div class="leftFloat">
      <h3>Import Data I/O Definition</h3>
    </div>
    <div class="rightFloat">
      <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
        <mat-icon class="dialog-close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="clearBoth mt-10">
    <app-file-upload #dataIOImport accept=".txt" text="Import Data Definition" (uploaded)="importDataDefinition($event)"></app-file-upload>
  </div>
</ng-template>

