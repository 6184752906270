import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthenticationService } from "../authentication.Service";

@Injectable({    
  providedIn: 'root'    
})    
export class HttpInterceptorService implements HttpInterceptor {
  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(public auth: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.testForAnonymousPath(request)) {
      return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }));
    }
    const modified = request.clone(this.addAuthenticationToken(request));
    return next.handle(modified).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (this.testForAnonymousPath(request)) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user and to redirect it to login page

          if (request.url.includes("refreshtoken")) {
            this.auth.logout();
          }

          return throwError(error);
        }

        if (error.status !== 401) {
          return throwError(error);
        }

        if (this.refreshTokenInProgress) {
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // – which means the new token is ready and we can retry the request again
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthenticationToken(request))));
        } else {
          this.refreshTokenInProgress = true;

          // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
          this.refreshTokenSubject.next(null);

          // Call auth.refreshAccessToken(this is an Observable that will be returned)
          return this.auth.refreshAccessToken().pipe(
            switchMap((result: any) => {
              //When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              this.refreshTokenInProgress = false;
              this.auth.setAccessToken(result.Token);
              this.refreshTokenSubject.next(result.Token);
              return next.handle(this.addAuthenticationToken(request, result.Token));
            })
            ,catchError((err: any) => {
              this.refreshTokenInProgress = false;
              this.auth.logout();
              return throwError(error);
            }));
        }
      }));

  }

  addAuthenticationToken(request: any, token?: string) {
    // Get access token from Local Storage
    let accessToken;
    if (token) {
      accessToken = token;
    }
    else {
      accessToken = this.auth.getAccessToken();
    }

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    const clone = request.clone();
    clone.headers = clone.headers.delete('Authorization');
    clone.headers = clone.headers.set('Authorization', `Bearer ${accessToken}`);
    return clone;
    // We clone the request, because the original request is immutable
    /*return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });*/
  }

  testForAnonymousPath(request: HttpRequest<any>): boolean {
    let requesturl = request.url.toLowerCase();
    return requesturl.includes("testtoken") || requesturl.includes("refreshtoken")
      || (requesturl.includes("login") && !requesturl.includes("loginas")) || requesturl.includes("register") || requesturl.includes("reset") || requesturl.includes("forgotpassword")
      || requesturl.includes("newsignup") || requesturl.includes("newlogin");
  }
}     
