<mat-accordion>
  <mat-expansion-panel hideToggle [expanded]="transferType == 'sms'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="sms-tools-titlepanel-div">
          Test Messaging
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <app-send></app-send>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="isBetaTester" hideToggle [expanded]="transferType == 'history'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="sms-tools-titlepanel-div">
          Message Analysis
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <app-traffic-analysis></app-traffic-analysis>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel hideToggle [expanded]="transferType == 'history'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="sms-tools-titlepanel-div">
          Message Insights
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <app-message-history [system]="isGlobal"></app-message-history>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel hideToggle [expanded]="transferType == 'network'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="sms-tools-titlepanel-div">
          Network / Country Lookup
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <app-manage-network></app-manage-network>
    </div>
  </mat-expansion-panel>
</mat-accordion>
