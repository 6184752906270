import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService, SecurityKey } from '../../authentication.Service';
import { CommsModel, CommsService, WhatsAppApplication, WhatsAppFullTemplate } from '../../comms.Service';
import { CommsEvent } from '../comm-editor/comm-editor.component';
import { serialize, deserialize, plainToClass } from 'class-transformer';

@Component({
  selector: 'app-comm-list',
  templateUrl: './comm-list.component.html',
  styleUrls: ['./comm-list.component.css']
})
export class CommListComponent {
  selectedIndex: number = 0;
  whatsAppStatus: number = -1;
  application: WhatsAppApplication;
  key: SecurityKey = null;
  details: any = [
    { name: 'Email', description: 'Email Communications ', icon: 'mail', comms: [] = [] },
    { name: 'WhatsApp', description: 'WhatsApp Communications ', icon: 'phone_iphone', comms: [] = [] },
    { name: 'SMS', description: 'SMS Communications ', icon: 'sms', comms: [] = [] }
  ];

  emailType: any = [
    { id: 1, name: 'Email Invitation', description: 'Email Invitation for a survey', admin: false },
    { id: 2, name: 'Email Reminder', description: 'Email Reminder for a survey', admin: false },
    { id: 3, name: 'Reset Password', description: 'Reset Password message for the system', admin: true },
    { id: 4, name: 'Registration Confirmation', description: 'Registration confirmation email for the system', admin: true },
    { id: 5, name: 'Forgot Password', description: 'Forgot password email for the system', admin: true },
    { id: 6, name: 'Email Change Warning', description: 'Change of email address warning for the system', admin: true },
    { id: 7, name: 'Username Change Warning', description: 'Change of username warning for the sytem', admin: true },
    { id: 8, name: 'Profile Change Warning', description: 'Change of profile warning for the system', admin: true },
    { id: 9, name: 'Organization Invitation', description: 'Organization Email Invitation for the sytem', admin: true },
    { id: 10, name: 'Non-Member Organization Invitation', description: 'Non-Member of the system Organization invitation for the system', admin: true },
    { id: 11, name: 'Report Invitation', description: 'Report invitation for the system', admin: true },
    { id: 12, name: 'Non-Member Report Invitation', description: 'Non-Member Report Invitation for the system', admin: true },
    { id: 14, name: 'Email 2FA', description: 'Two-Factor Authentication Email for the system', admin: true },
    { id: 15, name: 'Network Request', description: 'Network request email for the system', admin: true },
    { id: 16, name: 'Network Request Update', description: 'Network request update email for the system', admin: true},
    { id: 21, name: 'WhatsApp Application', description: 'WhatsApp application email for the system', admin: true },
    { id: 22, name: 'WhatsApp Application Update', description: 'WhatsApp application update email for the system', admin: true },
    { id: 23, name: 'DataIO Notification', description: 'Data Import/Export Notification email for users and admin', admin: true },
    { id: 24, name: 'Request Speed Management', description: 'Request Speed Management for API', admin: true }
  ];

  whatsAppType: any = [
    { id: 13, name: 'WhatsApp Invitation', description: 'WhatsApp Invitation for a survey', admin: false },
    { id: 17, name: 'WhatsApp Two Way Invitation', description: 'WhatsApp Invitation for a two-way WhatsApp survey', admin: false }
  ];

  smsType: any = [
    { id: 18, name: 'SMS Invitation', description: 'SMS Invitation for a survey', admin: false },
    { id: 19, name: 'SMS Reminder', description: 'SMS Reminder for a survey', admin: false },
    { id: 20, name: 'SMS 2FA', description: 'Two-Factor Authentication SMS for the system', admin: true }
  ];

  get isAdminUser(): boolean {
    return this.authenticationService.isAuthorized(['Admin']);
  }

  dialogRef: MatDialogRef<any, any>;

  get emailTypes(): any {
    this._emailTypes = this.getMenuTypes(0, this.emailType);
    return this._emailTypes;
  }
  private _emailTypes: any = null;

  get whatsAppTypes(): any {
    if (this._whatsAppTypes != null) {
      return this._whatsAppTypes;
    }

    this._whatsAppTypes = this.getMenuTypes(1, this.whatsAppType);
    return this._whatsAppTypes;
  }
  private _whatsAppTypes: any = null;

  get smsTypes(): any {
    this._smsTypes = this.getMenuTypes(2, this.smsType);
    return this._smsTypes;
  }
  private _smsTypes: any = null;

  getWhatsAppAccessToken() {
    this.authenticationService.getSecurityKeys('whatsapp').subscribe(
      result => {
        if (result.length > 0) {
          this.key = plainToClass(SecurityKey, result[0]);
        }
      });
  }

  getMenuTypes(index: number, menuTypes: any): any {
    let temp = [];
    for (let i = 0; i < menuTypes.length; i++) {
      let found = false;
      if (index != 1) {
        for (let j = 0; j < this.details[index].comms.length; j++) {
          if (this.details[index].comms[j].EmailType == menuTypes[i].id) {
            found = true;
            break;
          }
        }
      }

      if (!found && (this.isAdminUser && menuTypes[i].admin) || !menuTypes[i].admin) {
        temp.push(menuTypes[i]);
      }
    }

    return temp;
  }

  constructor(private commsService: CommsService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog) {
    this.getComms();
    this.commsService.getApplication().subscribe(
      result => {
        this.application = result;
        this.whatsAppStatus = result?.StatusId ?? -1;
        this.getWhatsAppAccessToken();
      }
    )
  }

  newComm(index: number, commType: number) {
    let comm = new CommsModel();
    comm.EmailType = commType;
    switch (index) {
      case 0:
        this.setComm(this.emailType, comm);
        break;
      case 2:
        this.setComm(this.smsType, comm);
        break;
    }

    this.selectedIndex = index;
    this.addComm(0, comm);
  }

  setComm(types: any, comm: CommsModel) {
    for (let i = 0; i < types.length; i++) {
      if (comm.EmailType == types[i].id) {
        comm.Name = types[i].name;
        comm.Description = types[i].description;
        return;
      }
    }
  }

  copyComm(oldComm: CommsModel, index: number) {
    let comm = new CommsModel();
    comm.EmailTemplateID = 0;
    comm.Name = oldComm.Name;
    comm.Description = oldComm.Description;
    comm.EmailContent = oldComm.EmailContent;
    comm.UserID = oldComm.UserID;
    comm.IsDeleted = oldComm.IsDeleted;
    comm.CreatedDate = new Date();
    comm.LastUpdatedDate = new Date();
    comm.Subject = oldComm.Subject;
    comm.EmailType = oldComm.EmailType;
    comm.DefaultThemeID = oldComm.DefaultThemeID;
    if (this.selectedIndex == 1) {
      try {
        let template = deserialize(WhatsAppFullTemplate, comm.EmailContent);
        template.template.status = "";
        comm.EmailContent = serialize(template)
      }
      catch (e) {
      }
    }
    this.addComm(index, comm);
  }

  addComm(index: number, comm: CommsModel) {
    this.details[this.selectedIndex].comms.splice(index, 0, comm);
  }

  getComms() {
    for (let i = 0; i < 3; i++) {
      if (i == 1) {
        this.commsService.getWhatsApptemplates().subscribe(
          result => this.details[i].comms = result);
      }
      else {
        this.commsService.getCommsByChannel(i).subscribe(
          result => this.details[i].comms = result);
      }
    }
  }

  deleteComm(index: number, comm: CommsModel) {
    for (let i = 0; i < this.details[index].comms.length; i++) {
      if (this.details[index].comms[i].EmailTemplateID == comm.EmailTemplateID) {
        if (index == 1) {
          this.commsService.deleteComm(comm).subscribe(result => {
            this.details[index].comms.splice(i, 1);
          });
        }
        else {
          comm.IsDeleted = true;
          this.commsService.setComm(comm).subscribe(result => {
            this.details[index].comms.splice(i, 1);
          });
        }

        return;
      }
    }
  }

  commClosed(event: CommsEvent, index: number) {
    switch (event.action) {
      case "closed":
        for (let i = 0; i < this.details[this.selectedIndex].comms.length; i++) {
          if (this.details[this.selectedIndex].comms[i].EmailTemplateID == event.comm.EmailTemplateID) {
            if (event.comm.EmailTemplateID == 0) {
              this.details[this.selectedIndex].comms.splice(i, 1);
              return;
            }
          }
        }

        break;
      case "copy":
        this.copyComm(event.comm, index);
        break;
      case "saved":
        for (let i = 0; i < this.details[this.selectedIndex].comms.length; i++) {
          if (this.details[this.selectedIndex].comms[i].EmailTemplateID == 0 || this.details[this.selectedIndex].comms[i].EmailTemplateID == event.comm.EmailTemplateID) {
            this.details[this.selectedIndex].comms[i] = event.comm;
            return;
          }
        }

        break;
      case "delete":
        this.deleteComm(this.selectedIndex, event.comm);
        break;
      case "lanuched":
        for (let i = 0; i < this.details[this.selectedIndex].comms.length; i++) {
          if (this.details[this.selectedIndex].comms[i].EmailTemplateID == event.comm.EmailTemplateID) {
            if (this.selectedIndex != 0) {
              this.details[0].comms.splice(0, 0, process);
              this.details[this.selectedIndex].comms.splice(i, 1);
            }

            return;
          }
        }

        break;
    }
  }


}
