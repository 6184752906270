import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Organization, AuthenticationService, OrganizationModel } from "../authentication.Service";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.css']
})
export class OrganizationsComponent implements OnInit {
  searchKey: string = "";
  organizations: Organization[] = [];
  displayedColumns: string[] = ['name', 'description', 'created', 'role', 'action'];
  dataSource = new MatTableDataSource<Organization>(this.organizations);
  resultsLength = 0;
  isAdminView = false;
  isMemberView = true;
  tempOrgs?: Observable<Organization[]>;
  orgPageSize: number = 20;
  userId: number = 0;

  @ViewChild('table', { static: true }) table: any | undefined;
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }


  constructor(public dialog: MatDialog,
    private authService: AuthenticationService,
    private actRoute: ActivatedRoute,
    public translate: TranslateService,
    private router: Router
  ) {
    switch (this.actRoute.snapshot.data.breadcrumb) {
      case "Organizations":
      case "Edit User":
      case "Profile":
      case "Profile Organization":
        break;
      default:
        this.displayedColumns = ['id', 'name', 'description', 'created', 'owner', 'role', 'action'];
        this.isAdminView = true;
        break;
    }

    this.userId = this.authService.getUserId();
    if (this.actRoute.snapshot.params.type == "user" && this.isAdminUser) {
      this.userId = Number(this.actRoute.snapshot.params.id);
    }

    this.getOrganizations();
  }

  ngOnInit(): void {
  }

  get isAdminUser(): boolean {
    return this.authService.isAuthorized(['Admin']);
  }

  getOrganizations() {
    if (this.isAdminView) {
      this.tempOrgs = this.authService.getOrganizations().pipe(tap<Organization[]>(result => {
        this.organizations = result as Organization[];
        this.loadOrgs();
      }));
    }
    else {
      this.isMemberView = true;
      if ((this.actRoute.snapshot.params.type == "user" && this.isAdminUser) || this.actRoute.snapshot.data.breadcrumb == "Profile"
                || this.actRoute.snapshot.data.breadcrumb == "Profile Organization") {
        this.tempOrgs = this.authService.getOrganizationsForSpecificMember(this.userId.toString()).pipe(tap<Organization[]>((result: Organization[]) => {
          this.organizations = result;
          this.loadOrgs();
        }));
      }
      else {
        this.tempOrgs = this.authService.getOrganizationsForMember().pipe(tap<Organization[]>(result => {
          this.organizations = result as Organization[];
          this.loadOrgs();
        }));
      }
    }
  }

  loadOrgs() {
    this.dataSource = new MatTableDataSource(this.organizations);
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.searchKey;
    this.resultsLength = this.organizations.length;
  }

  owner(organization: Organization): string {
    let owner = organization.Owner.UserName;
    if (organization.Owner.UserName !== organization.Owner.EmailAddress) {
      owner += " (" + organization.Owner.EmailAddress + ")";
    }

    return owner;
  }

  role(organization: Organization): string {
    if (organization.OwnerId === this.userId) {
      return "Owner";
    }

    for (let i = 0; i < organization.Members.length; i++) {
      if (organization.Members[i].UserID == this.userId) {
        return "Member";
      }
    }

    for (let i = 0; i < organization.Invitations?.length; i++) {
      if (organization.Invitations[i].UserId == this.userId) {
        return "Invited";
      }
    }

    return "Snubbed";
  }

  isOwner(organization: Organization): boolean {
    return organization.OwnerId === this.userId;
  }

  @Input()
  get displayTitle(): boolean {
    return this._displayTitle;
  }
  set displayTitle(value: boolean) {
    this._displayTitle = value;
  }
  private _displayTitle = true;

  description(organization: Organization): string {
    if (organization.Description === organization.Name) {
      return "";
    }

    return organization.Description;
  }

  deleteOrganization(id: number) {
    this.authService.deleteOrganization(id);
    this.getOrganizations();
  }

  leave(org: Organization) {

  }

  onClick(element: Organization) {
    if (this.isAdminView) {
      this.router.navigate(["/edituser", element.OwnerId, "user"]);
      return false;
    }

    if (this.isOwner(element)) {
      this.router.navigate(["/profile", element.OrganizationId ]);
    }

    return false;
  }
}
