<div>
  <mat-list [formGroup]="formGroup">
    <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
      <mat-label>Filter by Country</mat-label>
      <mat-select formControlName="Country">
        <mat-option value="">All Countries</mat-option>
        <mat-option *ngFor="let country of countries" [value]="country">{{country.Country}}</mat-option>
      </mat-select>
    </mat-form-field>&nbsp;

    <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
      <mat-label>Brand Type</mat-label>
      <mat-select>
        <mat-option value="1">Domestic</mat-option>
        <mat-option value="2">International</mat-option>
      </mat-select>
    </mat-form-field>&nbsp;

    <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
      <mat-label>Traffic Type</mat-label>
      <mat-select>
        <mat-option value="1">Transactional</mat-option>
        <mat-option value="2">Promotional</mat-option>
        </mat-select>
    </mat-form-field>&nbsp;

    <button mat-flat-button color="primary" (click)="newSearch()">Country Lookup</button>
    </mat-list>
</div>

<div>
  &nbsp;
</div>

<div *ngIf="dataReady">

  
    <h2>1- Way SMS (OutBound)</h2>
  <br />
  <div *ngFor="let number of numbers">
    {{number.Description}}
    <br />
    <h3>Message Capabilites :</h3>
    <ul>
      <li>Registration required : {{number.RegistrationRequired}}</li>
      <li>Alpha senderID supported : {{number.AlphaSenderIDSupport}}</li>
      <li>Concatenated SMS : {{number.Concatenation}}</li>
      <li>GSM standard encoding : {{number.GsmStandard}}</li>
      <li>GSM extended encoding : {{number.GsmExtendedEncoding}}</li>
      <li>Unicode encoding : {{number.Unicode}}</li>
      <li>Required information for registration : {{number.InfoForRegistartion}}</li>
      <li>Required documents for registration : {{number.DocumnetForRegistartion}}</li>
      <li>Registration fee : {{number.RegistartionFee}}</li>
    </ul>
  </div>
  <br />
</div>

<div>
  <div>
    <button mat-flat-button color="primary" (click)="newRequest()">New</button>
  </div>
</div>
<div>
  &nbsp;
</div>
<div class="outer-table-container">

  <div class="table-container custom-scroll-bar">
    <table mat-table #table [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           matSortActive="created"
           matSortDisableClear matSortDirection="desc">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let request"> {{request.Name}} </td>
      </ng-container>
      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Country </th>
        <td mat-cell *matCellDef="let request"> {{getNetwork(request).Name}} </td>
      </ng-container>
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Number </th>
        <td mat-cell *matCellDef="let request"> {{request.Number == undefined ? '' : request.Number.Number}} </td>
      </ng-container>
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
        <td mat-cell *matCellDef="let request"> {{request.Created |  date: 'short': '' : translate.currentLang}} </td>
      </ng-container>
      <ng-container matColumnDef="amended">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Modified </th>
        <td mat-cell *matCellDef="let request"> {{request.Amended | date:'short': '' : translate.currentLang}} </td>
      </ng-container>
      <ng-container matColumnDef="whiteliststatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Whitelist Status </th>
        <td mat-cell *matCellDef="let request"> {{request.Number == undefined ? '' : request.Number.WhitelistingRequired ? 'Required' : 'Not Required'}}</td>
      </ng-container>
      <ng-container matColumnDef="registrationstatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Registration Status </th>
        <td mat-cell *matCellDef="let request"> {{request.StatusDescription}} </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let request">
          <div>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(request)">
                <mat-icon>edit</mat-icon>
                <span>Review Connection</span>
              </button>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="delete(request)">
                <mat-icon>delete</mat-icon>
                <span>Delete Connection</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row *matRowDef="let request; columns: columns;"
          class="users-element-row" (click)="edit(request)">
      </tr>
    </table>
  </div>
</div>
