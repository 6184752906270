import { DatePipe } from '@angular/common';
import is from '@angular/common/locales/extra/is';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-advanced-timings',
  templateUrl: './advanced-timings.component.html',
  styleUrls: ['./advanced-timings.component.css']
})
export class AdvancedTimingsComponent implements OnInit {

  formGroup: FormGroup;
  detect: boolean = true;
  date: FormControl = new FormControl();

  @ViewChild('picker') public picker: any | undefined;

  constructor(private datePipe: DatePipe) {
    this.formGroup = new FormGroup({
      date: this.date
    })
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe(() => {
      if (this.detect) {
        this._datetime = new Date(this.formGroup.controls.date.value.getTime() - this._datetime.getTimezoneOffset() * 60000);
        this.valueChange.emit(this._datetime);
      }
    });

    this.populateForm();
  }

  @Input()
  get isUtc(): boolean {
    return this._isUtc;
  }
  set isUtc(value: boolean) {
    this._isUtc = value;
    if (this.formGroup) {
      this.populateForm();
    }
  }
  private _isUtc: boolean = true;

  @Input()
  label: string = "";

  @Input()
  placeholder: string = "";

  @Input()
  get value(): Date {
    return this._datetime;
  }
  set value(value: Date) {
    if (value instanceof Date) {
      this._datetime = value;
    }
    else {
      try {
        this._datetime = new Date(value);
      }
      catch { }
    }
    if (this.formGroup) {
      this.populateForm();
    }
  }
  private _datetime: Date = new Date();

  @Output() valueChange = new EventEmitter<Date>();

  private populateForm() {
    if (this.formGroup) {
      this.detect = false;
      if (this.isUtc) {
        let temp = moment(this._datetime).add(this._datetime.getTimezoneOffset(), 'm').toDate();
        this.formGroup.controls.date.setValue(temp);
      }
      else {
        this.formGroup.controls.date.setValue(this._datetime);
      }

      this.detect = true;
    }
  }

}
