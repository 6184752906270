import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Message, MessageService, MessageStatsSearchModel, MessageSummaryModel, MobileCountryInfo, ReceiptModel, TranLogResults, TranLogStatsModel, TranLogStatsResults } from '../../../message.Service';
import { Observable, of as observableOf } from "rxjs";
import { AuthenticationService, Metadata } from '../../../authentication.Service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MetadataTableComponent } from '../../../Development/metadata-entrytable/metadata-table.component';
import { SnackbarDefaultComponent } from '../../../snackbar/snackbar-default.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { UserFeatures, UserProfile, UserService } from '../../../user.Service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { JsonCyclic } from '../../../utils';
import * as WebDataRocks from '@webdatarocks/webdatarocks';
import { PivotTableComponent } from '../../pivot-table/pivot-table.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-traffic-analysis',
  templateUrl: './traffic-analysis.component.html',
  styleUrls: ['./traffic-analysis.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class TrafficAnalysisComponent {
  sb: MatSnackBarRef<any> | undefined;
  mess?: Observable<any>;
  messages: Message[] = [];
  total: number = 0;
  subTotal: number = 0;
  formGroup: FormGroup;
  range: FormGroup;
  metadata: Metadata[] = [];
  startDate: Date = null;
  endDate: Date = null;
  isSearching: boolean = false;
  isExporting: boolean = false;
  isLoadingResults: boolean = false;
  countries: MobileCountryInfo[] = [];
  index: number;
  data: any;
  page: number = 0;
  pageSize: number = 20;
  isRateLimitReached: boolean = false;
  ismapped: boolean = false;
  userFeatures: UserFeatures = new UserFeatures();
  users: UserProfile[] = [];
  stats: TranLogStatsModel[] = null;
  jsonData = null;
  /*[
    {
      "DateAdded": { type: "datetime" },
      "GatewayName": { type: "string" },
      "OwnerId": { type: "number" },
      "Account": { type: "string" },
      "ReceiptStauts": { type: "number" },
      "ReceiptStatusDescription": { type: "string" },
      "Segments": { type: "number" },
      "Messages": { type: "number" }
    }];*/
  dataReady: boolean = false;
  columnNames = [];
  pivot = {
    "dataSource": {
      "dataSourceType": "json",
      "data": this.jsonData
    },
    "slice": {
      "rows": [
        { "uniqueName": "GatewayName", "sort": "asc" },
        { "uniqueName": "Account", "sort": "asc" },
        { "uniqueName": "Country", "sort": "asc" },
        { "uniqueName": "Date", "sort": "asc" }
      ],
      "columns": [
        { "uniqueName": "ReceiptStatusDescription", "sort": "asc" }
      ],
      "measures": [
        { "uniqueName": "Segments", "aggregatation": "sum" }
      ],
      "expands": {
        "expandAll": false,
      },
      "drills": {
        "drillAll": false
      }
    },
    "options": {
      "grid": {
        "type": "compact",
        "title": "",
        "showFilter": true,
        "showHeaders": true,
        "showTotals": true,
        "showGrandTotals": "on",
        "showHierarchies": true,
        "showHierarchyCaptions": true,
        "showReportFiltersArea": true
      },
      "configuratorActive": false,
      "configuratorButton": true,
      "showAggregations": true,
      "showCalculatedValuesButton": true,
      "drillThrough": true,
      "showDrillThroughConfigurator": true,
      "sorting": "on",
      "datePattern": "dd/MM/yyyy",
      "dateTimePattern": "dd/MM/yyyy HH:mm:ss",
      "saveAllFormats": false,
      "showDefaultSlice": true,
      "defaultHierarchySortName": "asc"
    },
    "formats": [
      {
        "name": "",
        "thousandsSeparator": ",",
        "decimalSeparator": ".",
        "decimalPlaces": 0,
        "maxSymbols": 20,
        "currencySymbol": "",
        "currencySymbolAlign": "left",
        "nullValue": " ",
        "infinityValue": "Infinity",
        "divideByZeroValue": "Infinity"
      }
    ]
  }

  aggregators = ['Gateway', 'Country', 'Account', 'Date'];

  results: any = null;

  @ViewChild('messageDetail') messageDetailDialog: TemplateRef<any>;
  @ViewChild('metadataTable') metadataTable: MetadataTableComponent | undefined;
  @ViewChild('statuses') statuses: MatSelect | undefined;
  @ViewChild('pivotTable') pivotTable: PivotTableComponent | undefined;

  constructor(private dialog: MatDialog,
    private messageService: MessageService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private snackbar: MatSnackBar
  ) {
    if (!this.isAdminUser) {
      this.aggregators = ['Account', 'Country', 'Date'];
    }

    this.formGroup = new FormGroup({
      Number: new FormControl(),
      Direction: new FormControl(),
      MessageType: new FormControl(),
      Status: new FormControl(),
      Content: new FormControl(),
      Gateway: new FormControl(this.isAdminUser),
      Country: new FormControl(true),
      Account: new FormControl(true),
      Date: new FormControl(true)
    });

    this.startDate = new Date();
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate = new Date();
    this.endDate.setTime(new Date().getTime() + (24 * 60 * 60 * 1000));
    this.endDate.setHours(0, 0, 0, 0);
    this.formGroup.controls.Status.patchValue(["0"]);
    this.formGroup.controls.MessageType.setValue("Segments");
    this.messageService.getCountries().subscribe(result => {
      this.countries = result;
    });
    this.userService.getUserFeatures().subscribe(result => {
      this.userFeatures = result;
    });
  }

  ngOnInit(): void {
  }

  isAdmin(): boolean {
    return false;
  }

  getReceipt(receipt: ReceiptModel): string {
    if (this.isAdminUser || this.isImpersonating()) {
      return receipt?.GatewayReceiptID
    }

    let value = receipt?.GatewayReceiptID.split(":") ?? [];
    if (value.length > 1) {
      return value[1];
    }

    return "";
  }

  cellClick(event) {
    let a = event;
  }

  checkToolbar(toolbar) {
    const tabs = toolbar.getTabs();
    const traffic = this;
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      tabs.unshift({
        id: "wdr-tab-refresh",
        title: "Refresh",
        handler: () => {
          return new Promise(() => {
            setTimeout(() => {
              traffic.getMessages();
            })
          })
        },
        icon: this.icons.connect
      });
      return tabs;
    }
  }

  getType(message: Message) {
    switch (message.MessageType) {
      case 0:
        return "SMS";
      case 1:
        return "WHATSAPP";
      case 2:
        return "FACEBOOK";
      case 3:
        return "WHATSAPP TEMPLATE";
    }

    return "UNKONWN";
  }

  searchForMessages() {
    if (this.formGroup.invalid) {
      return;
    }

    let s = this.buildStatuses();
    this.isSearching = true;
    this.messages = [];
    this.page = 0;
    this.getMessages();
  }

  getMessages() {
    this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
    let model = new MessageStatsSearchModel();
    model.From = this.startDate ?? new Date("2024-01-01");
    model.To = this.endDate ?? new Date("2040-01-01");
    model.GatewayName = '';
    model.UserIds = [];
    for (let i = 0; i < this.users.length; i++) {
      model.UserIds.push(this.users[i].UserID);
    }

    model.Statuses = [];
    this.messageService.getTranlogStats(model).subscribe(
      results => {
        this.stats = results;
        let temp = new TranLogStatsResults(this.stats);
        for (let i = 0; i < temp.columns.length; i++) {
          this.columnNames.push({ "uniqueName": temp.columns[i], "sort": "asc" });
        }

        //this.jsonData = JsonCyclic.toJson(this.stats);
        this.pivot.dataSource.data = this.isAdminUser ? this.stats : temp.results;
        this.pivot.slice.measures[0].uniqueName = this.formGroup.controls.MessageType.value;
        let aggregators = this.calculateAggregators();
        this.pivot.slice.rows = aggregators.rows;
        this.dataReady = true;
        this.results = this.pivot;
        if (this.pivotTable != null && this.pivotTable != undefined) {
          this.pivotTable.report = this.results;
        }

        this.sb?.dismiss();
      }
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.aggregators, event.previousIndex, event.currentIndex);
    this.setAggregators();
  }

  setPivotMeasure() {
    this.pivot.slice.measures[0].uniqueName = this.formGroup.controls.MessageType.value;
    this.results = this.pivot;
    if (this.pivotTable != null && this.pivotTable != undefined) {
      this.pivotTable.report = this.results;
    }
  }

  calculateAggregators() {
    let a = {
      "rows": [
      ]
    };

    for (let i = 0; i < this.aggregators.length; i++) {
      switch (this.aggregators[i]) {
        case "Gateway":
          if (this.formGroup.controls.Gateway.value) {
            a.rows.push({ "uniqueName": "GatewayName", "sort": "asc" });
          }

          break;
        case "Account":
          if (this.formGroup.controls.Account.value) {
            a.rows.push({ "uniqueName": "Account", "sort": "asc" });
          }

          break;
        case "Country":
          if (this.formGroup.controls.Country.value) {
            a.rows.push({ "uniqueName": "Country", "sort": "asc" });
          }

          break;
        case "Date":
          if (this.formGroup.controls.Date.value) {
            a.rows.push({ "uniqueName": "Date", "sort": "asc" });
          }

          break;
      }
    }

    return a;
  }

  setAggregators() {
    let aggregators = this.calculateAggregators();
    this.pivot.slice.rows = aggregators.rows;
    if (this.pivotTable != null && this.pivotTable != undefined) {
      this.pivotTable.report = this.results;
    }
  }

  get isAdminUser(): boolean {
    return this.authenticationService.isAuthorized(['Admin']);
  }

  isImpersonating() {
    return this.authenticationService.impersonate();
  }

  buildStatuses(): number[] {
    let s: number[] = [];
    this.formGroup.controls.Status.value.forEach((data: string) => {
      switch (data) {
        case '0':
          break;
        case '1':
          s.push(0); s.push(11);
          break;
        case '2':
          s.push(1);
          break;
        case '3':
          s.push(3); s.push(4); s.push(5); s.push(6); s.push(8); s.push(9);
          s.push(10); s.push(12); s.push(13); s.push(14); s.push(16); s.push(17); s.push(18);
          s.push(19); s.push(20); s.push(21); s.push(22); s.push(23); s.push(24);
          break;
        case '4':
          s.push(4); s.push(7);
          break;
        case '5':
          s.push(8); s.push(12); s.push(16); s.push(22);
          break;
        case '6':
          s.push(6);
          break;
        case '7':
          s.push(10); s.push(19); s.push(21);
          break;
        case '8':
          s.push(15);
          break;
      }
    });
    return s;
  }

  exportMessages() {
    let s = this.buildStatuses();
    let date = new Date();
    this.isExporting = true;
    /*this.messageService.export(this.users.length > 0 ? this.users[0].UserID : 0, this._survey?.SurveyID ?? (this.system ? -1 : 0), this.startDate ?? new Date("2001-01-01"), this.endDate ?? new Date("2040-01-01"),
      this.formGroup.value.Country?.Codes, this.formGroup.value.Number, this.metadataTable?.metadata ?? [],
      Number(this.formGroup.controls.Direction.value), s, this.formGroup.value.Content, Number(this.formGroup.value.MessageType))
      .subscribe(
        response => {
          this.downLoadFile(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ".xlsx")
        },
        error => {
          this.isExporting = false;
        });*/
    return false;
  }

  downLoadFile(data: any, type: string, ext: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    //Download by dynamically creating a tag
    const a = document.createElement('a');
    const fileName = "messages";
    a.href = url;
    // a.download = fileName;
    a.download = fileName + ext;
    a.click();
    window.URL.revokeObjectURL(url);
    this.isExporting = false;
  }

  selectStatus(status: any) {
    this.statuses?.options.forEach((option: MatOption) => {
      if (option.value === status) {
        if (option.selected) {
          if (status === '0') {
            this.statuses?.options.forEach((data: MatOption) => {
              if (data.value !== "0") {
                data.deselect();
              }
            });
          }
          else {
            this.statuses?.options.first.deselect();
          }
        }
      }
    });
  }

  showMessageDetails(index: number, row: any) {
    this.index = index;
    this.data = row;
    let dialogRef = this.dialog.open(this.messageDetailDialog,
      {
        width: '80%'
      });
  }

  previousMessage() {
    this.index--;
    this.data = this.messages[this.index];
  }

  nextMessage() {
    this.index++;
    this.data = this.messages[this.index];
  }
}
