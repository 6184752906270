import { Component, OnInit, OnDestroy, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from "rxjs";
import { MissionService } from "../MissionService";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { UserService, UserProfile, Role, ShortCode } from '../user.Service';
import { PanelModel, PanelService } from '../panel.Service';
import { AuthenticationService, Organization, SecurityKey, SystemInvitation, UserInvite } from "../authentication.Service";
import { map, startWith, tap } from 'rxjs/operators';
import { SurveyService, SurveySummaryModel } from '../survey.Service';
import { ChangeUsernameComponent } from './change-username/change-username.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER, L } from '@angular/cdk/keycodes';
import { FinancialService } from '../finacial.Service';
import { InvitationsComponent } from '../invitations/invitations.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommsService, WhatsAppApplication } from '../comms.Service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MediaService } from '../media.Service';
import { HttpEventType } from '@angular/common/http';
import { MatTabGroup } from '@angular/material/tabs';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { JsonCyclic } from '../utils';
import { ImportProjectComponent } from './import-project/import-project.component';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  addOrInvite: boolean = false;
  sb: MatSnackBarRef<any> | undefined;
  key: SecurityKey = null;
  balance: number = 0;
  subscription: Subscription;
  userId: number = -1;
  tempUser: Observable<UserProfile>;
  tempSurveys?: Observable<SurveySummaryModel[]>
  tempOrgs?: Observable<Organization[]>
  surveys: SurveySummaryModel[] = [];
  organizations: Organization[] = null;
  draftSurveys: number = 0;
  latestDraft: Date = new Date();
  liveSurveys: number = 0;
  latestLive: Date = new Date();
  completedSurveys: number = 0;
  latestCompleted: Date = new Date();
  panels: number = 0;
  panel?: PanelModel;
  user: UserProfile = new UserProfile();
  formGroup: FormGroup = new FormGroup({});
  deleteForm: FormGroup = new FormGroup({});
  deleteUserForm: FormGroup = new FormGroup({});
  deleteValid: boolean = false;
  deleteUserValid: boolean = false;
  passwordChangeInvalid = true;
  passwordTimeout: any = null;
  opHide = true;
  pHide = true;
  cpHide = true;
  country: string = "";
  show2FA = false;
  viewingStatus: string = "Your personal account";
  isCurrentUser: boolean = true;
  isOrganization: boolean = false;
  currentUserId: number = -1;
  currentOrganization: Organization = new Organization();
  currentApplication: WhatsAppApplication = new WhatsAppApplication();
  whatsAppStatus: number = -1;
  invites: UserInvite[] = [];
  inviting = false;
  currentUser: UserProfile = new UserProfile();
  dialogRef: MatDialogRef<any, any>;
  tempNumbers: Observable<ShortCode[]>;
  numbers: ShortCode[] = [];
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  interestCtrl = new FormControl();
  filteredInterests: Observable<string[]>;
  loadingMembers = false;
  loadingInivtes = false;
  membersLoaded = false;
  selectedMembershipIndex = 0;
  interests: string[] = ['Brand Tracking'];
  allInterests: string[] = [
    'Brand Tracking',
    'Campaign Evaluation',
    'Creative Testing',
    'Product Development/Innovation',
    'PR/Content Marketing',
    'Brand/Competitor Analysis',
    'Consumer Profiling',
    'Category/Industry Research',
    'International Expansion',
    'Pricing'];

  @ViewChild('interestInput') interestInput: ElementRef<HTMLInputElement> | undefined;
  @ViewChild('invitations') invitations: InvitationsComponent | undefined;
  @ViewChild('deleteOrgDialog') deleteOrgDialog: TemplateRef<any>;
  @ViewChild('deleteUserDialog') deleteUserDialog: TemplateRef<any>;
  @ViewChild('invitationDialog') invitationDialog: TemplateRef<any>;
  @ViewChild('profileDialog') profileDialog: TemplateRef<any>;
  @ViewChild('membership') membership: MatTabGroup | undefined;
  @ViewChild('membershiplist') membershiplist: InvitationsComponent | undefined;

  jobRoles = [
    { id: 1, value: 'Insights or Research' },
    { id: 2, value: 'C-Suite or Founder' },
    { id: 3, value: 'Innovation, Product or UX' },
    { id: 4, value: 'Investment or Strategy' },
    { id: 5, value: 'Marketing or Brand' },
    { id: 6, value: 'Sales, Growth or Regional Leads' }
  ];

  companySectors = [
    { id: 1, value: 'IT/ITES' },
    { id: 2, value: 'Construction' },
    { id: 3, value: 'Independent' },
    { id: 4, value: 'Private Sector' },
    { id: 5, value: 'Public Sector' }
  ];

  companySizes = [
    { id: 1, value: 'Less than 50' },
    { id: 2, value: '50 to 100' },
    { id: 3, value: '100 to 1000' },
    { id: 4, value: '1000 to 5000' },
    { id: 5, value: 'More than 5000' }
  ];

  preferences = [
    { name: 'Survey Summary', description: 'Review surveys that are in draft, live and have run.' },
    { name: 'Message Analysis', description: 'Review message activity across all your networks.' },
    { name: 'Network Status', description: 'Manage your network requests and connections.' },
    { name: 'Panel Activity', description: 'View your panels and the activity they play in different research projects.' },
    { name: 'General Activity', description: 'Stay up to date with our general activity feed.' }
  ];

  get isAdminUser(): boolean {
    return this.authenticationService.isAuthorized(['Admin']);
  }

  get isDIYTestersUser(): boolean {
    return this.authenticationService.isAuthorized(['diy']);
  }

  get isDeveloper(): boolean {
    return this.authenticationService.isAuthorized(['developer']);
  }

  get isCreditCustomer(): boolean {
    if (this.user) {
      return UserService.hasRole(this.user.Member.Roles, "Credit");
    }

    return false;
  }

  import() {
    this.dialogRef = this.dialog.open(ImportProjectComponent, { data: this.userId , height: '80%', width: '80%' });
  }

  export() {
    this.sb = this.snackbar.open("Exporting...", "");
    this.surveyService.exportSurveys(this.userId).subscribe(
      response => {
        this.downLoadFile(response, "text/plain", ".diy")
      },
      error => {
        this.sb?.dismiss();
      });
    return false;

  }

  downLoadFile(data: any, type: string, ext: string) {
    let blob = new Blob([JsonCyclic.toJson(data)], { type: "text/plain" });
    let url = window.URL.createObjectURL(blob);
    //Download by dynamically creating a tag
    const a = document.createElement('a');
    const fileName = "project";
    a.href = url;
    // a.download = fileName;
    a.download = fileName + ext;
    a.click();
    window.URL.revokeObjectURL(url);
    this.sb?.dismiss();
  }

  show2FASteps(value: boolean) {
    this.show2FA = value;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.preferences, event.previousIndex, event.currentIndex);
  }

  constructor(
    public dialog: MatDialog,
    private financialService: FinancialService,
    private missionService: MissionService,
    private surveyService: SurveyService,
    private panelService: PanelService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private actRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private commsService: CommsService,
    private mediaService: MediaService
  ) {
    this.subscription = this.missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.onSubmit();
        }
      });

    this.filteredInterests = this.interestCtrl.valueChanges.pipe(
      startWith(null),
      map((interest: string | null) => (interest ? this._filter(interest) : this.allInterests.slice())),
    );
    if (this.actRoute.snapshot.data.breadcrumb == "Profile Organization") {
      this.currentUserId = this.authenticationService.getUserId();
      this.userId = this.actRoute.snapshot.params.id;
      this.authenticationService.testUserId(this.userId).subscribe(
        result => {
          let temp = result as number;
          if (temp = this.userId) {
            this.isOrganization = false;
          }
          else {
            this.isOrganization = true;
          }

          this.currentUserId = this.userId;
          this.loadPage();
        });
    }
    else {
      if (this.actRoute.snapshot.params.id == undefined) {
        this.userId = this.authenticationService.getUserId();
        this.currentUserId = this.userId;
        this.loadPage();
      }
      else {
        let tempId = Number(this.actRoute.snapshot.params.id);
        this.authenticationService.testUserId(tempId).subscribe(
          result => {
            let temp = result as number;
            if (temp == tempId) {
              if (this.isAdminUser) {
                this.viewingStatus = "Admin access to this account";
                this.userId = tempId;
                this.isCurrentUser = false;
              }
              else {
                this.userId = this.authenticationService.getUserId();
                this.currentUserId = this.userId;
                this.isOrganization = false;
                this.isCurrentUser = true;
              }

              this.currentUserId = this.userId;
              this.isOrganization = false;
            }
            else {
              if (this.isAdminUser) {
                this.viewingStatus = "Admin access to this account";
              }

              this.userId = tempId;
              this.currentUserId = temp;
              this.isCurrentUser = false;
              this.isOrganization = true;
            }

            this.loadPage();
          });
      }
    }
  }

  selectedFile: File | null = null;

  get initials() {
    return UserProfile.getInitial((this.isOrganization ? this.currentOrganization?.Name : this.currentUser?.UserName) ?? "");
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  media: string = null;

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
  }
  cropperReady() {
  }
  loadImageFailed() {
  }
  uploadPicture(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.size > 1048576) {
        this.openSnackbar("Please upload a picture smaller than 1 MB", "Cancel");
        return;
      }

      const mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }

      this.imageChangedEvent = event;
      this.dialogRef = this.dialog.open(this.profileDialog, { height: '390px' });
      this.dialogRef.afterClosed().subscribe(result => {
        if (result == undefined || result == 'no') {
          return;
        }

        let p = 0;
        this.mediaService.uploadBase64FileWithMediaId(this.croppedImage, this.currentUser.MediaId).subscribe(
          event => {
            if (event.type == HttpEventType.UploadProgress)
              p = Math.round(100 * event.loaded / event.total);
            else if (event.type === HttpEventType.Response) {
              if (this.isOrganization) {
                this.currentOrganization.MediaId = event.body.MediaID;
              }
              else {
                this.currentUser.MediaId = event.body.MediaID;
              }

              this.getMediaForProfile();
            }
          }, error => {
          });
      });
    }
  }

  getMediaForProfile() {
    this.mediaService.getMediaById(this.isOrganization ? this.currentOrganization.MediaId : this.currentUser.MediaId).subscribe(
      result => {
        this.media = result
      });
  }

  deleteMediaForProfile() {
    if (this.isOrganization) {
      this.currentOrganization.MediaId = 0;
    }
    else {
      this.currentUser.MediaId = 0;
    }

    this.media = null;
  }

  getMembersForOrg(): UserProfile[] {
    if (this.currentOrganization.Members == undefined && !this.loadingMembers) {
      this.loadingMembers = true;
      this.membersLoaded = false;
      this.authenticationService.getMembersForOrganization(this.currentOrganization.OrganizationId).subscribe(result => {
        this.currentOrganization.Members = result;
        this.membersLoaded = true;
        if (this.currentOrganization.Invitations == undefined && !this.loadingInivtes) {
          this.loadingInivtes = true;
          this.authenticationService.getInvitesForOrganization(this.currentOrganization.OrganizationId).subscribe(result => {
            this.currentOrganization.Invitations = result;
          });
        }
      });
    }

    return this.currentOrganization?.Members ?? [];
  }

  getWhatsAppAccessToken() {
    this.authenticationService.getSecurityKeysForAccount('whatsapp', this.userId).subscribe(
      result => {
        if (result.length > 0) {
          this.key = plainToClass(SecurityKey, result[0]);
        }
      });
  }

  setMembershipFocus() {
    if (this.membersLoaded && this.membershiplist && this.selectedMembershipIndex == 1) {
      this.selectedMembershipIndex = 0;
      this.membersLoaded = false;
    }
  }

  getInvitesForOrg(): SystemInvitation[] {
    return this.currentOrganization.Invitations
  }

  loadPage() {
    this.initForm();

    if (!this.isCurrentUser) {
      this.viewingStatus = this.isOrganization ? "Admin access to this organization" : "Admin access to this account";
      this.tempNumbers = this.userService.getNumbers(this.isOrganization ?
        this.currentOrganization.OwnerId.toString() :
        this.userId.toString()).pipe(tap<ShortCode[]>(
          result => {
            this.numbers = result
            this.numberList().clear();
            this.numbers.forEach(item => {
              this.numberList().push(this.createNumber(item.Code, item.Description, true));
            });
            this.numbers.push(new ShortCode());
            this.numberList().push(this.createNumber('', '', false));
          }));
      this.commsService.getApplicationForUser(this.currentUserId).subscribe(
        result => {
          this.currentApplication = result;
          this.whatsAppStatus = this.currentApplication?.StatusId ?? -1;
          this.getWhatsAppAccessToken();
        });
      this.tempUser = this.userService.getUser(this.currentUserId.toString()).pipe(tap<UserProfile>(
        result => {
          this.user = result;
          if (this.organizations == null || this.organizations.length == 0) {
            this.tempOrgs = this.authenticationService.getOrganizationsForSpecificUser(this.currentUserId.toString()).pipe(tap<Organization[]>(result => {
              this.organizations = result;
              if (this.currentOrganization.OrganizationId == 0) {
                for (let i = 0; i < this.organizations.length; i++) {
                  if (this.userId == this.organizations[i].MemberId) {
                    this.currentOrganization = this.organizations[i];
                    break;
                  }
                }
              }

              this.loadForms();
              this.loadOrgs();
            }));
          }
          else {
            this.loadForms();
            this.tempOrgs = new Observable((observer) => { observer.next(this.organizations) });
          }
        }));
      return;
    }

    if (this.currentUserId != this.userId || this.isOrganization) {
      // Must be an organization
      if (this.user.UserID == 0) {
        this.commsService.getApplicationForUser(this.isOrganization ? this.currentOrganization.MemberId : this.currentUserId).subscribe(
          result => {
            this.currentApplication = result;
            this.whatsAppStatus = this.currentApplication?.StatusId ?? -1;
            this.getWhatsAppAccessToken();
          });
        this.tempUser = this.userService.getCurrentUser().pipe(tap<UserProfile>(
          result => {
            this.user = result;
            this.getOrgs();
          }));
      }
      else {
        this.commsService.getApplicationForUser(this.isOrganization ? this.currentOrganization.MemberId : this.currentUserId).subscribe(
          result => {
            this.currentApplication = result;
            this.whatsAppStatus = this.currentApplication?.StatusId ?? -1;
            this.getWhatsAppAccessToken();
          });
        this.getOrgs();
        this.loadForms();
      }

      return;
    }

    this.commsService.getApplication().subscribe(
      result => {
        this.currentApplication = result;
        this.whatsAppStatus = this.currentApplication?.StatusId ?? -1;
      });
    this.tempNumbers = this.userService.getNumbersForCurrentUser().pipe(tap<ShortCode[]>(result => {
      this.numbers = result
      this.numberList().clear();
      this.numbers.forEach(item => {
        this.numberList().push(this.createNumber(item.Code, item.Description, true));
      });
      this.numbers.push(new ShortCode());
      this.numberList().push(this.createNumber('', '', false));
    }));
    this.tempUser = this.userService.getCurrentUser().pipe(tap<UserProfile>(
      result => {
        this.user = result;
        this.loadForms();
        if (this.organizations == null || this.organizations.length == 0) {
          this.tempOrgs = this.authenticationService.getOrganizationsForOwner().pipe(tap<Organization[]>(result => {
            this.organizations = result;
            this.loadOrgs();
          }));
        }
        else {
          this.tempOrgs = new Observable((observer) => { observer.next(this.organizations) });
        }
      }));
  }

  private getOrgs() {
    if (this.organizations == null || this.organizations.length == 0) {
      if (this.isCurrentUser) {
        this.tempOrgs = this.authenticationService.getOrganizationsForOwner().pipe(tap<Organization[]>(result => {
          this.loadOrgsFromServer(result);
        }));
      }
      else {
        this.tempOrgs = this.authenticationService.getOrganizationsForSpecificUser(this.currentUserId.toString()).pipe(tap<Organization[]>(result => {
          this.loadOrgsFromServer(result);
        }));
      }

    }
    else {
      this.tempOrgs = new Observable((observer) => { observer.next(this.organizations) });
      this.loadForms();
    }
  }

  private loadOrgsFromServer(results: Organization[]) {
    this.organizations = results;
    this.loadOrgs();
    if (this.currentOrganization.OrganizationId == 0) {
      this.currentOrganization = this.organizations[1];
    }

    this.loadForms();
  }

  private loadForms() {
    if (this.currentUser.UserID == 0) {
      this.currentUser = this.user;
    }

    this.formGroup.patchValue(this.user);
    if (this.isOrganization) {
      this.formGroup.controls.UserName.setValue(this.currentOrganization.Name);
      this.formGroup.controls.Description.setValue(this.currentOrganization.Description);
    }

    this.getBasicDetails()
    this.setBillingType();
    this.setRoles();
    this.setPreferences();
  }

  ngOnInit(): void {
    if (this.isAdminUser) {
      this.draftSurveys = 0;
      this.latestDraft = null;
      this.latestLive = null;
      this.latestCompleted = null;
      this.liveSurveys = 0;
      this.completedSurveys = 0;
      this.tempSurveys = this.surveyService.getSurveySummariesForUser(this.userId.toString()).pipe(tap<SurveySummaryModel[]>(
        result => {
          this.surveys = result;
          this.surveys.forEach(survey => {
            switch (survey.Status) {
              case 1:
                this.draftSurveys++;
                if (this.latestDraft) {
                  if (survey.LastUpdated > this.latestDraft) {
                    this.latestDraft = survey.LastUpdated;
                  }
                }
                else {
                  this.latestDraft = survey.LastUpdated;
                }

                break;
              case 2:
              case 3:
                this.liveSurveys++;
                if (this.latestLive) {
                  if (survey.LastUpdated > this.latestLive) {
                    this.latestLive = survey.LastUpdated;
                  }
                }
                else {
                  this.latestLive = survey.LastUpdated;
                }

                break;
              case 4:
              case 5:
              case 6:
                this.completedSurveys++;
                if (this.latestCompleted) {
                  if (survey.LastUpdated > this.latestCompleted) {
                    this.latestCompleted = survey.LastUpdated;
                  }
                }
                else {
                  this.latestCompleted = survey.LastUpdated;
                }

                break;
            }
          })
        }));
      this.panelService.getLatestPanel(this.userId.toString()).subscribe(result => this.panel = result);
      this.panelService.panelCountForUser(this.userId.toString()).subscribe(result => this.panels = result);
    }
  }

  changeAccount(index: number, event) {
    this.currentOrganization = this.organizations[index];
    if (this.currentOrganization.OrganizationId == 0) {
      this.userId = this.currentUserId;
      this.isOrganization = false;
    }
    else {
      this.userId = this.currentOrganization.MemberId;
      this.isOrganization = true;
    }

    this.loadPage();
    this.ngOnInit();
  }

  setPreferences() {
    this.formGroup.controls.Theme.setValue(this.user.UserFeatures.GeneralFeatures.Theme);
    this.formGroup.controls.ProfessionalEditor.setValue(this.user.UserFeatures.GeneralFeatures.ProfessionalEditor ?? false);
    this.formGroup.controls.EditQuestionName.setValue(this.user.UserFeatures.QuestionEditorFeatures.EditQuestionName);
    this.formGroup.controls.EditAnswerOptions.setValue(this.user.UserFeatures.QuestionEditorFeatures.EditAnswerOptions);
    this.formGroup.controls.EditAnswerOptionFactors.setValue(this.user.UserFeatures.QuestionEditorFeatures.EditAnswerOptionFactors);
    this.formGroup.controls.AccessToAdvancedAnalysisOptions.setValue(this.user.UserFeatures.DataAnalysisFeatures.AccessToAdvancedAnalysisOptions);
    this.formGroup.controls.MakeProfilePrivate.setValue(this.user.UserFeatures.AccountFeatures.MakeProfilePrivate);
  }

  getBasicDetails() {
    if (this.isOrganization && this.currentOrganization.MediaId != 0 || !this.isOrganization && this.user.MediaId != 0) {
      this.getMediaForProfile();;
    }
    else {
      this.media = null;
    }

    this.interests = this.user.Interests === "" ? [] : this.user.Interests.split(",");
    this.country = this.user.Country;
    this.show2FASteps(this.user.TwoFA > 0);
  }

  setRoles() {
    let roles: string[] = [];
    const tempRoles = ['Admin', 'sa', 'diy', 'sms', 'developer', 'advanced', 'template', 'beta'];
    tempRoles.forEach(tempRole => {
      if (UserService.hasRole(this.user.Member.Roles, tempRole)) {
        roles.push(tempRole);
      }
    });

    this.formGroup.controls.Roles.setValue(roles);
  }

  setBillingType() {
    this.formGroup.controls.BillingType.setValue("Cash");
    if (UserService.hasRole(this.user.Member.Roles, "Cash")) {
      this.formGroup.controls.BillingType.setValue("Cash");
    }

    if (UserService.hasRole(this.user.Member.Roles, "Credit")) {
      this.formGroup.controls.BillingType.setValue("Credit");
    }

    this.financialService.checkBalanceForUser(this.user.UserID).subscribe(result => {
      this.balance = result
    });
  }

  initForm() {
    this.formGroup = new FormGroup({
      UserName: new FormControl("", [Validators.required]),
      FirstName: new FormControl("", [Validators.required]),
      LastName: new FormControl(),
      EmailAddress: new FormControl(),
      Description: new FormControl(),
      MobileNumber: new FormControl(),
      IsDeleted: new FormControl(),
      CreatedDate: new FormControl(),
      LastUpdatedDate: new FormControl(),
      Roles: new FormControl([]),
      Status: new FormControl(),
      SharedKey: new FormControl(),
      PanelPricingModel: new FormControl(),
      PanelAmount: new FormControl(),
      CompleteCost: new FormControl(),
      CintCompleteCost: new FormControl(),
      OldPassword: new FormControl(),
      NewPassword: new FormControl(),
      ConfirmPassword: new FormControl(),
      BillingType: new FormControl(),
      Company: new FormControl(),
      JobRole: new FormControl(),
      CompanySector: new FormControl(),
      CompanySize: new FormControl(),
      YourInterests: new FormControl(),
      MakeProfilePrivate: new FormControl(false),
      ZendeskUsername: new FormControl(),
      Numbers: this.formBuilder.array([this.createNumber('', '', false)]),
      Theme: new FormControl(),
      ProfessionalEditor: new FormControl(),
      EditQuestionName: new FormControl(),
      EditAnswerOptions: new FormControl(),
      EditAnswerOptionFactors: new FormControl(),
      AccessToAdvancedAnalysisOptions: new FormControl(),
      Bio: new FormControl()
    });

    this.deleteForm = new FormGroup({
      Delete: new FormControl("", [Validators.required])
    });
    this.deleteForm.valueChanges.subscribe(result => {
      this.deleteValid = this.deleteForm.controls.Delete.value == this.currentOrganization.Name;
    });

    this.deleteUserForm = new FormGroup({
      Delete: new FormControl("", [Validators.required])
    });
    this.deleteUserForm.valueChanges.subscribe(result => {
      this.deleteUserValid = this.deleteUserForm.controls.Delete.value == this.user.UserName;
    });
    this.loadingMembers = false;
    this.loadingInivtes = false;
  }

  createNumber(code: string, desc: string, disable: boolean): FormGroup {
    return this.formBuilder.group({
      Code: new FormControl({ value: code, disabled: disable }),
      Description: new FormControl({ value: desc, disabled: false })
    });
  }

  numberList(): FormArray {
    return this.formGroup.get('Numbers') as FormArray;
  }

  addOrRemove(i: number) {
    if (this.numbers[i].Code === "") {
      return "add";
    }

    return "remove";
  }

  addNumber(event: any, i: number, flag: string) {
    let code = this.numberList().controls[i].get("Code")?.value;
    if (flag === "remove") {
      this.numbers.splice(i, 1);
      this.numberList().removeAt(i);
      return;
    }

    if (code === "") {
      this.numberList().controls[i].get("Code")?.markAsTouched();
      return;
    }

    code = code.trim();
    let found = false;
    this.numbers.forEach(item => {
      if (item.Code === code) {
        this.numberList().controls[i].get("Code")?.markAsTouched();
        this.openSnackbar("You cannot have duplicate numbers.", "Dismiss");
        found = true;
        return;
      }
    });

    if (found) {
      return;
    }

    this.userService.checkNumber(this.userId.toString(), code).subscribe(
      result => {
        this.numberList().controls[i].get("Code")?.markAsTouched();
        this.openSnackbar("This number is already associated with another account - " + result.SigninName, "Dismiss");
      },
      error => {
        this.numbers[i].Code = code;
        this.numbers[i].Description = this.numberList().controls[i].get("Description")?.value;
        this.numberList().controls[i].get("Code")?.disable();
        this.numbers.push(new ShortCode());
        this.numberList().push(this.createNumber("", "", false));
      });
  }

  updateGeneralDetails(basic: boolean) {
    this.currentOrganization.Name = this.formGroup.controls.UserName.value;
    this.currentOrganization.Description = this.formGroup.controls.Description.value;
    if (this.isCurrentUser) {
      this.authenticationService.saveOrganization(this.currentOrganization.OrganizationId.toString(),
        this.currentOrganization.Name, this.currentOrganization.Description, [], this.currentOrganization.MediaId).subscribe(result => {
          if (basic) {
            this.openSnackbar("Profile has successfully been updated.", "");
            return;
          }

          let roles = this.updateRoles(true);
          this.userService.updateRolesForOrganization(Number(this.currentOrganization.OrganizationId), Number(this.formGroup.value.Status), roles).subscribe(result => {
            this.openSnackbar("General Details have successfully been updated.", "");
          });
        });
      return false;
    }

    this.authenticationService.saveOrganizationForUser(Number(this.currentUserId), this.currentOrganization.OrganizationId.toString(),
      this.currentOrganization.Name, this.currentOrganization.Description, [], this.currentOrganization.MediaId).subscribe(result => {
        if (basic) {
          this.openSnackbar("Profile has successfully been updated.", "");
          return;
        }

        let roles = this.updateRoles(true);
        this.userService.updateRolesForOrganization(Number(this.currentOrganization.OrganizationId), Number(this.formGroup.value.Status), roles).subscribe(result => {
          this.openSnackbar("General Details have successfully been updated.", "");
        });
      });
    return false;
  }

  invitePeople() {
    this.inviting = true;
    this.dialogRef = this.dialog.open(this.invitationDialog, {});
    this.dialogRef.afterClosed().subscribe(result => {
      if (result == undefined || result == 'no') {
        this.inviting = false;
        return;
      }

      this.authenticationService.invite(this.currentOrganization.OrganizationId.toString(), this.invites).subscribe(result => {
        let invitations = result as SystemInvitation[];
        for (let i = 0; i < invitations.length; i++) {
          this.currentOrganization.Invitations.push(invitations[i]);
        }

        this.invites = [];
        this.invitations?.refresh();
        this.inviting = false;
        this.openSnackbar("Invitations were successfully sent.", "");
      },
        error => {
          this.inviting = false;
          this.openSnackbar("There was a problem sending the invitations - please check your invite list.", "Cancel");
        });
    });

    return false;
  }

  addPeople() {
    this.inviting = true;
    this.addOrInvite = true;
    this.dialogRef = this.dialog.open(this.invitationDialog);
    this.dialogRef.afterClosed().subscribe(result => {
      if (result == undefined || result == 'no') {
        this.inviting = false;
        return;
      }

      this.authenticationService.addPeople(this.currentOrganization.OrganizationId.toString(), this.invites).subscribe(result => {
        this.invites = [];
        this.invitations?.refresh();
        this.inviting = false;
        this.currentOrganization.Members = undefined;
        this.loadingMembers = false;
        this.openSnackbar("People were successfully added.", "");
      },
        error => {
          this.inviting = false;
          this.openSnackbar("There was a problem add the people - please check your list as it can only contain members of the platform.", "Cancel");
        });
    });

    return false;
  }

  updateOwnedNumbers() {
    let savedNumbers: ShortCode[] = [];
    for (let i = 0; i < this.numbers.length - 1; i++) {
      savedNumbers.push(this.numbers[i]);
    }
    this.numbers.pop();
    this.userService.setNumbers(this.userId.toString(), savedNumbers).subscribe(
      result => {
        this.openSnackbar("Owned Numbers have successfully been updated.", "");
      });
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  message() {
    return;
  }

  updateAccountDetailsFields() {
    this.user.UserName = this.formGroup.value.UserName;
    this.user.EmailAddress = this.formGroup.value.EmailAddress;
  }

  updateAccountDetails() {
    this.user.Status = Number(this.formGroup.value.Status);
    this.updateAccountDetailsFields();
    this.user.Member.Roles = this.updateRoles(false);
    this.userService.setUser(this.user).subscribe(result => {
      this.openSnackbar("Account Details have successfully been updated.", "");
      if (this.isCurrentUser) {
        this.authenticationService.refreshToken();
      }
    });
    return false;
  }

  updateBasicDetailsFeilds() {
    this.user.FirstName = this.formGroup.value.FirstName;
    this.user.LastName = this.formGroup.value.LastName;
    this.user.Bio = this.formGroup.value.Bio;
    this.user.MobileNumber = this.formGroup.value.MobileNumber;
    this.user.Country = this.country;
    this.user.Company = this.formGroup.value.Company;
    this.user.CompanySector = Number(this.formGroup.value.CompanySector);
    this.user.CompanySize = Number(this.formGroup.value.CompanySize);
    this.user.UserFeatures.AccountFeatures.MakeProfilePrivate = this.formGroup.value.MakeProfilePrivate;
    this.user.Interests = this.interests.join(",");
    this.user.ZendeskUsername = this.formGroup.value.ZendeskUsername;
  }

  updateBasicDetails() {
    if (this.isOrganization) {
      this.updateGeneralDetails(true);
      return false;
    }
    this.updateBasicDetailsFeilds()
    this.userService.setUser(this.user).subscribe(result => {
      this.openSnackbar("Profile has successfully been updated.", "");
      if (this.isCurrentUser) {
        this.authenticationService.refreshToken();
      }
    });
    return false;
  }

  updatePricingStructureFields() {
    this.user.PanelPricingModel = Number(this.formGroup.value.PanelPricingModel);
    this.user.PanelAmount = Number(this.formGroup.value.PanelAmount);
    this.user.CintCompleteCost = Number(this.formGroup.value.CintCompleteCost);
    this.user.CompleteCost = Number(this.formGroup.value.CompleteCost);
    this.user.Member.Roles = this.updateRoles(this.isOrganization);
  }

  updatePricingStructure() {
    this.updatePricingStructureFields();
    this.userService.setUser(this.user).subscribe(result => {
      this.openSnackbar("Pricing has successfully been updated.", "");
    });
    return false;
  }

  updateSpecialDeveloperSettingsFields() {
    this.user.SharedKey = this.formGroup.value.SharedKey;
  }

  updateSpecialDeveloperSettings() {
    this.updateSpecialDeveloperSettingsFields();
    this.userService.setUser(this.user).subscribe(result => {
      this.openSnackbar("Special Developer Settings have successfully been updated.", "");
    });
    return false;
  }

  checkRole(name: string, userRoles: Role[]): Role[] {
    if (this.formGroup.controls.Roles.value.includes(name)) {
      let role: Role = { RoleId: 0, RoleName: name };
      userRoles.push(role);
    }

    return userRoles;
  }

  updatePreferences() {
    this.user.UserFeatures.GeneralFeatures.Theme = this.formGroup.controls.Theme.value;
    this.user.UserFeatures.GeneralFeatures.ProfessionalEditor = this.formGroup.controls.ProfessionalEditor.value;
    this.user.UserFeatures.QuestionEditorFeatures.EditQuestionName = this.formGroup.controls.EditQuestionName.value;
    this.user.UserFeatures.QuestionEditorFeatures.EditAnswerOptions = this.formGroup.controls.EditAnswerOptions.value;
    this.user.UserFeatures.QuestionEditorFeatures.EditAnswerOptionFactors = this.formGroup.controls.EditAnswerOptionFactors.value;
    this.user.UserFeatures.DataAnalysisFeatures.AccessToAdvancedAnalysisOptions = this.formGroup.controls.AccessToAdvancedAnalysisOptions.value;
    this.userService.setUser(this.user).subscribe(result => {
      this.openSnackbar("Experience Preferences have successfully been updated.", "");
    });
    return false;
  }

  updateRoles(isOrganization: boolean): Role[] {
    let userRoles: Role[] = [];
    if (isOrganization) {
      let role: Role = { RoleId: 0, RoleName: "Organization" };
      userRoles.push(role);
    }

    userRoles = this.checkRole("Admin", userRoles);
    userRoles = this.checkRole("sa", userRoles);
    userRoles = this.checkRole("diy", userRoles);
    userRoles = this.checkRole("sms", userRoles);
    userRoles = this.checkRole("developer", userRoles);
    userRoles = this.checkRole("advanced", userRoles);
    userRoles = this.checkRole("template", userRoles);
    userRoles = this.checkRole("beta", userRoles);

    let billingType = this.formGroup.controls.BillingType.value;
    if (billingType) {
      if (billingType === "Cash") {
        let role: Role = { RoleId: 0, RoleName: 'Cash' };
        userRoles.push(role);
      }

      if (billingType === "Credit") {
        let role: Role = { RoleId: 0, RoleName: 'Credit' };
        userRoles.push(role);
      }
    }
    else {
      let role: Role = { RoleId: 0, RoleName: 'Cash' };
      userRoles.push(role);
    }

    return userRoles
  }

  onSubmit() {
    this.router.navigate(['/users']);
  }

  requestWhatsAppAccessToken() {
    this.router.navigate(['/whatsapp', 0, 'whatsapp', this.userId, this.isCurrentUser ? 'editprofile' : 'user']);
  }

  createWhatsAppAccessToken() {
    this.router.navigate(["/securitykey", 0, 'whatsapp', this.userId, this.isCurrentUser ? 'editprofile' : 'user']);
  }

  viewTransactions() {
    if (this.isCurrentUser) {
      this.router.navigate(['../fintrans']);
    }
    else {
      this.router.navigate(['../fintrans', this.userId]);
    }
    return false;
  }

  onImpersonate(event: any) {
    if (UserService.hasRole(this.currentUser.Member.Roles, "Admin") || UserService.hasRole(this.currentUser.Member.Roles, "sa")) {
      event.preventDefault();
      this.authenticationService.impersonateAs(this.currentUser.UserName)
        .subscribe(result => {
          const token = (result as any).Token;
          this.authenticationService.setAccessToken(token);
          if (this.isOrganization) {
            this.authenticationService.setOrganization("/surveydashboard", this.currentOrganization.OrganizationId);
            return;
          }

          this.surveyService.getSurveyCount()
            .subscribe(response => {
              if (response > 0) {
                this.router.navigate(['../surveydashboard']);
              } else {
                this.router.navigate(['../startfromtemplate']);
              }
            },
              error => {
                if (error === 401) {
                  this.router.navigate(['../']);
                }
              });
        },
          error => {
            console.error(error);
          });
      return false;
    }

    this.openSnackbar("This user needs a role to be able to sign in.", "Dismiss");
    return false;
  }

  onSignin(event: any) {
    if (UserService.hasRole(this.currentUser.Member.Roles, "Admin") || UserService.hasRole(this.currentUser.Member.Roles, "sa")) {
      event.preventDefault();
      this.authenticationService.loginAs(this.currentUser.UserName)
        .subscribe(result => {
          const token = (result as any).Token;
          this.authenticationService.setAccessToken(token);
          if (this.isOrganization) {
            this.authenticationService.setOrganization("/surveydashboard", this.currentOrganization.OrganizationId);
            return;
          }

          this.surveyService.getSurveyCount()
            .subscribe(response => {
              if (response > 0) {
                this.router.navigate(['../surveydashboard']);
              } else {
                this.router.navigate(['../startfromtemplate']);
              }
            },
              error => {
                if (error === 401) {
                  this.router.navigate(['../']);
                }
              });
        },
          error => {
            console.error(error);
          });
      return false;
    }

    this.openSnackbar("This user needs a role to be able to sign in.", "Dismiss");
    return false;
  }

  checkPassword(event: any) {
    if (this.passwordTimeout != null) {
      clearTimeout(this.passwordTimeout);
    }

    this.passwordTimeout = setTimeout(() => {
      this.passwordChangeInvalid = true;
      const name = this.formGroup.controls.ConfirmPassword.value;
      if (!name) {
        return;
      }
      if (name.trim().length === 0) {
        return;
      }

      this.passwordChecks(name);
    }, 500);
  }

  passwordChecks(name: string) {
    if (this.formGroup.controls.NewPassword.value === this.formGroup.controls.ConfirmPassword.value) {
      this.passwordChangeInvalid = false;
    }

  }

  changePassword() {
    this.authenticationService.changePassword(this.formGroup.controls.OldPassword.value, this.formGroup.controls.NewPassword.value).subscribe(
      result => {
        this.openSnackbar("Password updated successfully.", "");
        return false;
      },
      error => {
        this.openSnackbar("The password could not be changed, please check your credentials.", "Dismiss");
        return false;
      }
    );
    return false;
  }

  changeEmailAddress() {
    const dialogRef = this.dialog.open(ChangeEmailComponent, {});
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.email) {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      }

      return false;
    });
    return false;
  }

  changeUsername() {
    let dialogRef;
    if (this.userId > 0) {
      dialogRef = this.dialog.open(ChangeUsernameComponent, {
        data: {
          id: this.isOrganization ? this.currentOrganization.OrganizationId : Number(this.userId), type: this.isOrganization ? 'organization' : 'user'
        }
      });
    }
    else {
      dialogRef = this.dialog.open(ChangeUsernameComponent, { data: { type: 'user' } });
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.username) {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      }

      return false;
    });
    return false;
  }


  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.interests.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.interestCtrl.setValue(null);
  }

  remove(interest: string): void {
    const index = this.interests.indexOf(interest);

    if (index >= 0) {
      this.interests.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.interests.push(event.option.viewValue);
    if (this.interestInput != undefined) {
      this.interestInput.nativeElement.value = '';
    }

    this.interestCtrl.setValue(null);
  }

  resetPassword(): boolean {
    this.authenticationService.requestResetPassword(this.formGroup.value.UserName)
      .subscribe(result => {
        this.openSnackbar("A reset email was sent to the user.", "");
      },
        error => {
          this.openSnackbar("There was a problem producing the user's reset email.", "Dismiss");
        });
    return false;
  }

  deleteAccount(): boolean {
    this.deleteValid = false;
    this.deleteForm.controls.Delete.setValue("");
    this.dialogRef = this.dialog.open(this.deleteUserDialog);
    return false;
  }

  deleteOrganization(): boolean {
    this.deleteValid = false;
    this.deleteForm.controls.Delete.setValue("");
    this.dialogRef = this.dialog.open(this.deleteOrgDialog);
    return false;
  }

  transferOrganization(): boolean {
    this.router.navigate(["/transfer", this.currentOrganization.OrganizationId, "organization"]);
    return false;
  }

  confirmDeleteOrganization() {
    this.authenticationService.deleteOrganization(this.currentOrganization.OrganizationId);
    this.openSnackbar("Organization is being deleted.", "");
    this.changeAccount(0, null);
    this.organizations = null;
    this.dialogRef.close();
  }

  confirmDeleteAccount() {
    this.authenticationService.deleteUser(this.currentUser.UserID).subscribe(result => {
      this.openSnackbar("Account has been deleted.", "");
      this.dialogRef.close();
      if (this.isAdminUser) {
        this.router.navigate(['/users']);
      }
      else {
        this.router.navigate(['/login']);
      }
    },
      error => {
        this.openSnackbar("There was a problem deleting the account.", "Cancel");
        this.dialogRef.close();
      });

    return false;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allInterests.filter(interest => interest.toLowerCase().includes(filterValue));
  }

  private loadOrgs() {
    let org = new Organization();
    org.Name = this.user.UserName;
    this.organizations.splice(0, 0, org);
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}
